import { SharedService } from './../../services/shared/shared.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { MatDrawerMode } from '@angular/material/sidenav';
import {
  EventBusService,
  EventBusNames
} from '../../services/event-bus/event-bus.service';
import { ProfileService } from '../../services/user/profile/profile.service';
import { Router } from '@angular/router';
import { TwilioService } from '../../services/user/twilio/twilio.service';
import { AppConstantsService } from '../../services/app-constants/app-constants.service';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  mode: MatDrawerMode = 'over';
  opened = false;
  opacityHeader = false;

  constructor(
    public authService: AuthService,
    private eventBusService: EventBusService,
    // todo: not remove profileService!!! to get user data
    // @ts-ignore-line
    private profileService: ProfileService,
    // todo: not remove twilioService!!! to connect twilioService
    // @ts-ignore
    private twilioService: TwilioService,
    // todo: not remove constantsService!!! to get app constants
    // @ts-ignore
    private constantsService: AppConstantsService,
    private router: Router,
    private readonly sharedService: SharedService
  ) {
    this.eventBusService.emit({ name: EventBusNames.authorized });
    router.events.subscribe(() => {
      this.opacityHeader = this.isOpacityHeader();
    });
  }

  isOpacityHeader(): boolean {
    return !!this.router.url.match(/profile-and-settings\/\d/);
  }

  ngOnInit(): void {
    this.onResize();
  }

  onResize($event?: any): void {
    const width = $event ? $event.target.innerWidth : window.innerWidth;
    const maxWidth = 959;

    this.mode = width > maxWidth ? 'side' : 'over';
    this.opened = width > maxWidth;
  }

  get disableClose(): boolean {
    return this.mode === 'side';
  }

  get isLoading(): boolean {
    // TODO: fix
    return false;
    // return this.sharedService.isLoading;
  }
}
