import { CalendarEventType } from './calendar.event.type';
import { WorkspaceEvent } from './workspaceEvent';
import { TaskInfo } from './task.info';

export class CalendarEvent {
  type: CalendarEventType;
  data: WorkspaceEvent | TaskInfo;
  date: string | undefined;

  constructor(type: CalendarEventType, data: WorkspaceEvent | TaskInfo, date: string | undefined) {
    this.type = type;
    this.data = data;
    this.date = date;
  }

  static fromJson(json: any): CalendarEvent {
    const type: CalendarEventType =
      CalendarEventType[json.type as keyof typeof CalendarEventType];
    let data: any = null;
    let date: string | undefined;

    switch (type) {
      case CalendarEventType.WORKSPACE_EVENT:
        data = new WorkspaceEvent(json.data.workspaceId).deserialize(json.data);
        date = data.dateStart;
        break;

      case CalendarEventType.TASK:
        data = new TaskInfo().deserialize(json.data);
        date = data.dueDate;
        break;

      default:
        console.warn(
          'Could not deserialize CalendarEvent: unknown type ' + type
        );
    }

    return new CalendarEvent(type, data, date);
  }

  static fromJsonArray(jsonArray: any): Array<CalendarEvent> {
    const result: Array<CalendarEvent> = [];
    for (const record of jsonArray) {
      const event = CalendarEvent.fromJson(record);
      result.push(event);
    }
    return result;
  }
}
