import { Injectable } from '@angular/core';
import { EventTypes, WorkspaceEvent } from '../../models/workspaceEvent';
import {
  CreatedEventsList,
  WorkspaceEventList
} from '../../models/workspaceEventList';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from '../user/profile/profile.service';
import {
  Attendance,
  Participant,
  EventParticipant
} from '../../models/participant';
import { ActualValues, AttendanceObject } from '../../models/AttendanceObject';
import {
  TextInputComponent,
  TextInputDialogModel
} from '../../component/dialog/text-input/text-input.component';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ParticipantItem } from 'src/app/standalone/one-participant/participant.model';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceEventsService {
  constructor(
    private httpClient: HttpClient,
    private profileService: ProfileService,
    private dialog: MatDialog
  ) {}

  urlConflicts(workspaceId: number): string {
    return `${environment.url}/workspaces/${workspaceId}/events/with/users/by/attendance`;
  }

  urlWorkspaceEvents(workspaceId: number): string {
    return `${environment.url}/workspaces/${workspaceId}/events`;
  }

  urlWorkspaceEvent(eventId: number, workspaceId: number): string {
    return `${this.urlWorkspaceEvents(workspaceId)}/${eventId}`;
  }

  getEvents(
    workspaceId: number,
    limit: number,
    page: number,
    type: EventTypes | null = null
  ): Observable<WorkspaceEventList> {
    let params = { page, limit };
    if (type) {
      params = Object.assign(params, { type });
    }

    return this.httpClient
      .get<WorkspaceEventList>(this.urlWorkspaceEvents(workspaceId), { params })
      .pipe(
        map((response) => {
          response.content = response.content.map((event) =>
            new WorkspaceEvent(event.workspaceId).deserialize(event)
          );
          return response;
        }),
        catchError((error) =>
          this.handleError('get events', {} as WorkspaceEventList, error)
        )
      );
  }

  getConflicts(
    workspaceId: number,
    page: number,
    limit: number
  ): Observable<WorkspaceEvent[]> {
    const params = { page, limit, attendance: Attendance.Rejected };
    return this.httpClient
      .get<WorkspaceEventList>(this.urlConflicts(workspaceId), { params })
      .pipe(
        map((response) => {
          return response.content.map((event) =>
            new WorkspaceEvent(event.workspaceId).deserialize(event)
          );
        }),
        catchError((error) => this.handleError('get conflicts', [], error))
      );
  }

  private handleError<T>(
    title: string,
    payload: T,
    error: Error
  ): Observable<T> {
    console.error('There was an error with ', title, payload, error);
    return of(payload);
  }

  getEventById(eventId: number, workspaceId: number): Promise<any> {
    return lastValueFrom(
      this.httpClient.get(
        environment.url + 'workspaces/' + workspaceId + '/events/' + eventId
      )
    );
  }

  addEventParticipants(
    event: WorkspaceEvent,
    participants: ParticipantItem[]
  ): Observable<WorkspaceEvent> {
    const users = participants.map((p) => p.userId);
    return this.httpClient
      .put(
        environment.url +
          'workspaces/' +
          event.workspaceId +
          '/events/' +
          event.eventId +
          '/attendances',
        { users }
      )
      .pipe(map(() => event));
  }

  createEvents(
    events: WorkspaceEvent[],
    workspaceId: number
  ): Observable<WorkspaceEvent[]> {
    return this.httpClient
      .post<CreatedEventsList>(
        environment.url + `workspaces/${workspaceId}/events/batch`,
        events
      )
      .pipe(
        map((response) => {
          return response.data.map((event) =>
            new WorkspaceEvent(workspaceId).deserialize(event)
          );
        }),
        catchError((err) => this.handleError('create events', events, err))
      );
  }

  createEvent(
    event: WorkspaceEvent,
    addParticipants: boolean = true
  ): Observable<WorkspaceEvent> {
    event.baseId = null;
    return this.createEvents([event], event.workspaceId).pipe(
      map((response) => {
        return response[0];
      }),
      mergeMap((ev) => {
        if (event.participants.length > 0 && addParticipants) {
          return this.addEventParticipants(ev, event.participants).pipe(
            map(() => ev)
          );
        }
        return of(ev);
      })
    );
  }

  /**
   * EventId is passed into this function separately because depending on requirements in can be either eventId or baseId
   */
  updateEvent(
    event: WorkspaceEvent,
    eventId: number
  ): Observable<WorkspaceEvent> {
    return this.httpClient
      .put<WorkspaceEvent>(
        this.urlWorkspaceEvent(eventId, event.workspaceId),
        event
      )
      .pipe(map((ev) => new WorkspaceEvent(event.workspaceId).deserialize(ev)));
  }

  deleteEventById(eventId: number, workspaceId: number): Observable<any> {
    return this.httpClient.delete(this.urlWorkspaceEvent(eventId, workspaceId));
  }

  isEventCreator(event: WorkspaceEvent): boolean {
    const user = this.profileService.user;
    if (user) {
      return event.inviterId === user.userId;
    }
    return false;
  }

  getActualFromEvent(event: WorkspaceEvent): ActualValues {
    return event.isNotStarted ? ActualValues.user : ActualValues.mentor;
  }

  showRejectReasonDialog(reason: string): MatDialogRef<any> {
    const message = `It's really important for us to know`;
    const title = 'Indicate the reason';
    const dialogData = new TextInputDialogModel(
      title,
      message,
      reason,
      !this.profileService.isAdmin(),
      'Send',
      255
    );

    return this.dialog.open(TextInputComponent, {
      panelClass: 'popup-dialog',
      data: dialogData
    });
  }

  getOneAttendanceByUserId(userId: number, event: WorkspaceEvent): string {
    const participant = this.getOneParticipantByUserId(userId, event);
    return participant !== undefined && participant.reason
      ? participant.reason
      : '';
  }

  getOneParticipantByUserId(
    userId: number,
    event: WorkspaceEvent
  ): EventParticipant | undefined {
    return event.participants.find((p) => p.userId === userId);
  }

  canManageEvent(participants: Participant[]): boolean {
    return this.profileService.isAdminOrManager(participants);
  }

  currentEventParticipant(event: WorkspaceEvent): EventParticipant | undefined {
    const currentUserId = this.profileService.user?.userId;
    return currentUserId
      ? this.getOneParticipantByUserId(currentUserId, event)
      : undefined;
  }

  currentUserStatusFor(event: WorkspaceEvent): Attendance {
    const currentStatus = this.currentEventParticipant(event)?.attendance;
    if (currentStatus) {
      return currentStatus;
    }
    return Attendance.Unmarked;
  }

  updateUsersListRequest(
    eventId: number,
    workspaceId: number,
    userIds: number[],
    action: 'add' | 'remove'
  ): Observable<any> {
    return action === 'remove'
      ? this.removeUsersFromEvent(eventId, workspaceId, userIds)
      : this.addUsersToEvent(eventId, workspaceId, userIds);
  }

  addUsersToEvent(
    eventId: number,
    workspaceId: number,
    userIds: number[]
  ): Observable<any> {
    const url = this.urlWorkspaceEvent(eventId, workspaceId) + '/attendances';
    return this.httpClient.put(url, { users: userIds });
  }

  updateAttendances(
    workspaceId: number,
    attendances: AttendanceObject[]
  ): Promise<any> {
    return lastValueFrom(
      this.httpClient.put(
        environment.url + 'workspaces/' + workspaceId + '/events/attendances',
        {
          attendances
        }
      )
    );
  }

  removeUsersFromEvent(
    eventId: number,
    workspaceId: number,
    userIds: number[]
  ): Observable<any> {
    const url =
      this.urlWorkspaceEvent(eventId, workspaceId) + '/attendances/delete';
    return this.httpClient.post(url, { users: userIds });
  }

  deleteUserFromEvent(
    workspaceId: number,
    eventId: number,
    participants: EventParticipant[]
  ): Promise<any> {
    const users = participants.map((p) => p.userId);
    const url =
      this.urlWorkspaceEvent(eventId, workspaceId) + '/attendances/delete';
    return lastValueFrom(this.httpClient.post(url, { users }));
  }
}
