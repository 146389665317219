<div class="big-calendar-container" fxFlex="0 1 700px">
  <div class="row text-center">
    <div
      class="calendar-navigation"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start center"
    >
      <span class="month">{{
        currentDate.toLocaleString('default', {
          month: 'long',
          year: 'numeric'
        })
      }}</span>

      <ng-content
        class="additional-toolbar"
        select="[toolbar-items]"
      ></ng-content>

      <div
        class="calendar-navigation-buttons"
        fxLayout="row"
        fxFlex
        fxLayoutAlign="end center"
        fxLayoutGap="30px"
        fxLayoutGap.lt-sm="7px"
      >
        <ng-container *ngIf="showsToolBar">
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="7px">
            <button mat-button class="btn-filter" (click)="openFilterDialog()">
              <mat-icon>filter_list</mat-icon>
            </button>
            <button
              *ngIf="displaySyncCalendarOption"
              mat-button
              class="btn-refresh"
              (click)="synchronize()"
            >
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
        </ng-container>

        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="7px">
          <button
            mat-button
            class="btn-prev"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="currentDate"
          >
            &larr;
          </button>
          <button
            mat-button
            class="btn-today"
            fxHide.lt-sm
            mwlCalendarToday
            [(viewDate)]="currentDate"
          >
            Today
          </button>
          <button
            mat-button
            class="btn-prev"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="currentDate"
          >
            &rarr;
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="calendar">
    <mwl-calendar-month-view
      #monthCalendar
      [viewDate]="currentDate"
      [activeDay]="activeDate"
      [events]="events"
      [refresh]="onRefresh"
      [draggable]="false"
      [activeDayIsOpen]="true"
      (beforeViewRender)="beforeViewCalendar($event)"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (swipeleft)="onSwipeLeft($event)"
      (swiperight)="onSwipeRight($event)"
    >
    </mwl-calendar-month-view>
  </div>
</div>
