import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-right-column',
  templateUrl: './right-column.component.html',
  styleUrls: ['./right-column.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class RightColumnComponent implements OnInit, OnDestroy {
  activeImg = 1;
  idTimer: NodeJS.Timeout | undefined;

  demoPages = [
    {
      index: 1,
      title: 'Create Your Show',
      mainImage: 'assets/first-page-layout/right-column/Image1.png',
      imageSet:
        'assets/first-page-layout/right-column/Image1x1_5.png 1.5x, assets/first-page-layout/right-column/Image1x2.png 2x, assets/first-page-layout/right-column/Image1x3.png 3x, assets/first-page-layout/right-column/Image1x4.png 4x',
      iconPassive:
        'assets/first-page-layout/right-column/icons/create_show.svg',
      iconActive:
        'assets/first-page-layout/right-column/icons/create_show_active.svg'
    },
    {
      index: 2,
      title: 'Invite Cast & Crew',
      mainImage: 'assets/first-page-layout/right-column/Image2.png',
      imageSet:
        'assets/first-page-layout/right-column/Image2x1_5.png 1.5x, assets/first-page-layout/right-column/Image2x2.png 2x, assets/first-page-layout/right-column/Image2x3.png 3x, assets/first-page-layout/right-column/Image2x4.png 4x',
      iconPassive:
        'assets/first-page-layout/right-column/icons/invite_cast.svg',
      iconActive:
        'assets/first-page-layout/right-column/icons/invite_cast_active.svg'
    },
    {
      index: 3,
      title: 'Share Sheet Music',
      mainImage: 'assets/first-page-layout/right-column/Image3.png',
      imageSet:
        'assets/first-page-layout/right-column/Image3x2.png 1.5x, assets/first-page-layout/right-column/Image3x2.png 2x, assets/first-page-layout/right-column/Image3x3.png 3x, assets/first-page-layout/right-column/Image3x4.png 4x',
      iconPassive:
        'assets/first-page-layout/right-column/icons/share_sheet.svg',
      iconActive:
        'assets/first-page-layout/right-column/icons/share_sheet_active.svg'
    },
    {
      index: 4,
      title: 'Track Conflicts',
      mainImage: 'assets/first-page-layout/right-column/Image4.png',
      imageSet:
        'assets/first-page-layout/right-column/Image4x2.png 1.5x, assets/first-page-layout/right-column/Image4x2.png 2x, assets/first-page-layout/right-column/Image4x3.png 3x, assets/first-page-layout/right-column/Image4x4.png 4x',
      iconPassive: 'assets/first-page-layout/right-column/icons/conflicts.svg',
      iconActive:
        'assets/first-page-layout/right-column/icons/conflicts_active.svg'
    },
    {
      index: 5,
      title: 'Rehearsal Reminders',
      mainImage: 'assets/first-page-layout/right-column/Image5.png',
      imageSet:
        'assets/first-page-layout/right-column/Image5x2.png 1.5x, assets/first-page-layout/right-column/Image5x2.png 2x, assets/first-page-layout/right-column/Image5x3.png 3x, assets/first-page-layout/right-column/Image5x4.png 4x',
      iconPassive: 'assets/first-page-layout/right-column/icons/reminders.svg',
      iconActive:
        'assets/first-page-layout/right-column/icons/reminders_active.svg'
    },
    {
      index: 6,
      title: 'Assign Show Tasks',
      mainImage: 'assets/first-page-layout/right-column/Image6.png',
      imageSet:
        'assets/first-page-layout/right-column/Image6x2.png 1.5x, assets/first-page-layout/right-column/Image6x2.png 2x, assets/first-page-layout/right-column/Image6x3.png 3x, assets/first-page-layout/right-column/Image6x4.png 4x',
      iconPassive:
        'assets/first-page-layout/right-column/icons/assign_tasks.svg',
      iconActive:
        'assets/first-page-layout/right-column/icons/assign_tasks_active.svg'
    },
    {
      index: 7,
      title: 'Plan Events',
      mainImage: 'assets/first-page-layout/right-column/Image7.png',
      imageSet:
        'assets/first-page-layout/right-column/Image7x1_5.png 1.5x, assets/first-page-layout/right-column/Image7x2.png 2x, assets/first-page-layout/right-column/Image7x3.png 3x, assets/first-page-layout/right-column/Image7x4.png 4x',
      iconPassive: 'assets/first-page-layout/right-column/icons/Calendar.svg',
      iconActive:
        'assets/first-page-layout/right-column/icons/Calendar_active.svg'
    }
  ];

  constructor() {}

  ngOnInit(): void {
    this.next();
  }

  showMore(): void {
    window.open('https://bit.ly/actorspocketguidevideo', '_blank');
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  setActive(i: number): void {
    this.activeImg = i;
    this.next();
  }

  destroy(): void {
    if (this.idTimer) {
      clearInterval(this.idTimer);
      this.idTimer = undefined;
    }
  }

  next(): void {
    this.destroy();
    this.idTimer = setInterval(
      () => (this.activeImg > 5 ? (this.activeImg = 1) : this.activeImg++),
      5000
    );
  }
}
