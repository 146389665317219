export const environment = {
  production: false,
  debugTwilio: false,
  url: 'https://dev.actorspocketguide.com/',
  appUrl: 'https://web-dev.actorspocketguide.com/',
  wsUrl: 'https://dev-rtn.actorspocketguide.com/rts/rts-messaging',
  appVersion: require('../../package.json').version + '-dev',
  googleCalendar: {
    clientId:
      '954539990645-k7kao208udnhj9m8gcf5ub60t9tbd97n.apps.googleusercontent.com'
  },
  apiVersion: 10,
  firebase: {
    apiKey: 'AIzaSyC-kHutZUmoNs8GnIK-5vQtcfHnwK1Z9a4',
    authDomain: 'apg-staging.firebaseapp.com',
    projectId: 'apg-staging',
    storageBucket: 'apg-staging.appspot.com',
    messagingSenderId: '604075391577',
    appId: '1:604075391577:web:136e12dc59d8a39a4fcf48',
    measurementId: 'G-F4RMS7NVMY'
  }
};