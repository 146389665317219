import * as moment from 'moment';
import { Frequency, RRule } from 'rrule';
import { RecurrenceFrequency } from '../models/recurrenceRule';
import * as debug from 'debug';

const trace = debug('apg:recurrence-helper');

export class RecurrenceHelper {
  static toRRuleFrequency(freq: RecurrenceFrequency): Frequency {
    switch (freq) {
      case 'DAILY':
        return Frequency.DAILY;
      case 'MONTHLY':
        return Frequency.MONTHLY;
      case 'WEEKLY':
        return Frequency.WEEKLY;
      case 'YEARLY':
        return Frequency.YEARLY;
    }
  }
  static toRecurrenceFrequency(freq: Frequency): RecurrenceFrequency {
    switch (freq) {
      case Frequency.DAILY:
        return 'DAILY';
      case Frequency.WEEKLY:
        return 'WEEKLY';
      case Frequency.YEARLY:
        return 'YEARLY';
      case Frequency.MONTHLY:
        return 'MONTHLY';
      default:
        return 'DAILY';
    }
  }

  static toRecurrenceRules(rrule: RRule): number[] {
    switch (rrule.options.freq) {
      case Frequency.DAILY:
        return [];
      case Frequency.WEEKLY:
        return rrule.options.byweekday.map((d) => d + 1);
      case Frequency.MONTHLY:
        return rrule.options.bymonthday;
      case Frequency.YEARLY:
        return rrule.options.bymonth;
      default:
        return [];
    }
  }

  static toUnitsString(freq: Frequency): string {
    switch (freq) {
      case Frequency.DAILY:
        return 'Day';
      case Frequency.MONTHLY:
        return 'Month';
      case Frequency.WEEKLY:
        return 'Week';
      case Frequency.YEARLY:
        return 'Year';
      default:
        return '';
    }
  }

  static presetTitleForFrequency(freq: Frequency): string {
    switch (freq) {
      case Frequency.DAILY:
        return 'Every day';
      case Frequency.MONTHLY:
        return 'Eveny month';
      case Frequency.WEEKLY:
        return 'Every week';
      case Frequency.YEARLY:
        return 'Every year';
      default:
        return '';
    }
  }

  static isRulePreset(rule: RRule): boolean {
    return (
      this.isDailyPreset(rule) ||
      this.isWeeklyPreset(rule) ||
      this.isMonthlyPreset(rule)
    );
  }

  static isDailyPreset(rule: RRule): boolean {
    return rule.options.freq === Frequency.DAILY && rule.options.interval === 1;
  }

  static isWeeklyPreset(rule: RRule): boolean {
    if (rule.options.freq === Frequency.WEEKLY && rule.options.interval === 1) {
      const onWeekDays = rule.options.byweekday;
      if (onWeekDays.length > 1) {
        return false;
      }
      let day = moment.utc(rule.options.dtstart).day();
      if (day === 0) {
        day = 7;
      }
      return onWeekDays[0] === day - 1;
    } else {
      return false;
    }
  }

  static isMonthlyPreset(rule: RRule): boolean {
    if (
      rule.options.freq === Frequency.MONTHLY &&
      rule.options.interval === 1
    ) {
      const onMonthDays = rule.options.bymonthday;
      if (onMonthDays.length > 1) {
        return false;
      }
      return onMonthDays[0] === moment.utc(rule.options.dtstart).date();
    } else {
      return false;
    }
  }

  static recalculateRuleParams(
    newStartDate: moment.Moment,
    rrule: RRule
  ): RRule {
    const rule = rrule;
    trace('Requested rule recalc for date: ', newStartDate);

    if (!this.isRulePreset(rule)) {
      trace('Rule is customized, not recalculating default params');

      rule.options.dtstart = newStartDate.toDate();
      return rule;
    }

    rule.options.dtstart = newStartDate.toDate();

    switch (rule.options.freq) {
      case Frequency.WEEKLY:
        let day = newStartDate.day();
        if (day === 0) {
          day = 7;
        }
        rule.options.byweekday = [day - 1];
        break;
      case Frequency.MONTHLY:
        rule.options.bymonthday = [newStartDate.date()];
        break;
      default:
        break;
    }
    trace('Rule recalculation result: ', rule);
    return rule;
  }
}
