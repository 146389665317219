import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog
} from '@angular/material/legacy-dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  NGX_MAT_DATE_FORMATS,
  NGX_MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
  NgxMatDateFormats
} from '@angular-material-components/datetime-picker';
import {
  FormValidatorService,
  greaterThan
} from '../../../../services/form-validators/form-validator.service';
import { EventTypes, WorkspaceEvent } from '../../../../models/workspaceEvent';
import { WorkspaceEventsService } from '../../../../services/events/workspace-events.service';
import * as moment from 'moment/moment';
import { FnMentionableSearchParticipant } from '../../../../directive/mentionable.fn.search.participant';
import { Observable, Subscriber } from 'rxjs';
import { MentionableParticipant } from '../../../../directive/mentionable.participant';
import { Workspace } from '../../../../models/workspace';
import { StringUtils } from '../../../../common/string-utils';
import { MentionableDirective } from '../../../../directive/mentionable.directive';
import { PlatformUtils } from '../../../../common/platform-utils';
import { MentionablePosition } from '../../../../directive/mentionable.position';
import { finalize, first, pairwise, startWith } from 'rxjs/operators';
import { ActiveWorkspaceService } from 'src/app/services/workspace/active-workspace.service';
import { SearchWorkspaceFieldComponent } from 'src/app/pages/workspace/search-workspace-field/search-workspace-field.component';
import { MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER as MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/legacy-autocomplete';
import { MAT_LEGACY_SELECT_SCROLL_STRATEGY_PROVIDER as MAT_SELECT_SCROLL_STRATEGY_PROVIDER } from '@angular/material/legacy-select';
import { RRule } from 'rrule';
import { RecurrenceRule } from 'src/app/models/recurrenceRule';
import { RecurringEventsService } from 'src/app/services/recurring-events/recurring-events.service';
import { RecurrenceHelper } from 'src/app/common/recurrence-utils';
import { SearchResultModel } from 'src/app/services/workspace/workspace.service';
import {
  ExtendedParticipant,
  toExtendedParticipant
} from 'src/app/models/participant';
import { clone } from 'lodash';

/**
 * Documentation datetime picker https://www.npmjs.com/package/@angular-material-components/datetime-picker
 * Displaying formats: https://momentjs.com/docs/#/displaying/
 */

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'M/D h:mm a'
  },
  display: {
    dateInput: 'M/D h:mm a',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-dialog-add-update-event',
  templateUrl: './dialog-add-update-event.html',
  styleUrls: [
    '../../../../pages/workspace/workspace.component.scss',
    './dialog-add-update-event.component.scss',
    '../add-update..scss'
  ],
  providers: [
    MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
    MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
    NGX_MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ]
})
export class DialogAddUpdateEventComponent implements OnInit {
  MentionablePosition = MentionablePosition;

  eventForm: FormGroup = new FormGroup({});

  editMode = false;
  clicked = true;
  allEventTypes: EventTypeOption[] = [];
  formSubmitAttempt = false;
  loadInProgress = false;
  shouldSaveOnSubmit = true;

  type: 'add' | 'update' = 'add';
  recurrenceRule: RRule | null = null;
  showRepeatEventComponent = false;

  event: WorkspaceEvent;
  originalEvent: WorkspaceEvent;
  workspace?: Workspace;
  @ViewChild(MentionableDirective) mentionableDirective?: MentionableDirective;

  @ViewChild('searchWorkspaceField')
  searchWorkspaceField?: SearchWorkspaceFieldComponent;

  selectedWorkspace: SearchResultModel | null = null;
  // Todo duplicate
  searchWorkspaceParticipants: FnMentionableSearchParticipant = (
    keyword: string
  ) => {
    return new Observable<Array<MentionableParticipant>>(
      (subscriber: Subscriber<Array<MentionableParticipant>>) => {
        keyword = keyword.trim();
        const participants: Array<ExtendedParticipant> =
          this.workspace?.participants.map(toExtendedParticipant) || [];
        const foundParticipants = participants.filter(
          (participant: ExtendedParticipant) => {
            return (
              participant.getId() &&
              participant
                .getVisibleName()
                .toLowerCase()
                .includes(keyword.toLowerCase())
            );
          }
        );

        subscriber.next(foundParticipants);
        subscriber.complete();
      }
    );
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) data: WorkspaceData,
    public eventsService: WorkspaceEventsService,
    public dialogRef: MatDialogRef<DialogAddUpdateEventComponent>,
    private formValidator: FormValidatorService,
    private activeWorkspaceService: ActiveWorkspaceService,
    private recurringEventsService: RecurringEventsService,
    private dialog: MatLegacyDialog
  ) {
    this.type = data.type;
    this.event = clone(data.event);
    this.workspace = clone(data.workspace);
    this.originalEvent = clone(data.event);
    this.shouldSaveOnSubmit = data.shouldSaveOnSubmit ?? true;

    const keys = Object.keys(EventTypes);
    for (const key of keys) {
      // @ts-ignore
      this.allEventTypes.push(new EventTypeOption(key, EventTypes[key]));
    }
  }

  ngOnInit(): void {
    this.eventForm = new FormGroup({
      type: new FormControl(this.event.type, [
        Validators.required,
        Validators.maxLength(255)
      ]),
      workspaceId: new FormControl(null, []),
      name: new FormControl(this.event.name, [
        Validators.required,
        Validators.maxLength(255)
      ]),
      dateStart: new FormControl(moment.utc(this.event.dateStart).local(), [
        Validators.required
      ]),
      dateEnd: new FormControl(moment.utc(this.event.dateEnd).local(), [
        greaterThan('dateStart')
      ]),
      location: new FormControl(this.event.location, [
        Validators.maxLength(255)
      ]),
      link: new FormControl(this.event.link, [Validators.maxLength(255)]),
      description: new FormControl(this.event.description, [
        Validators.maxLength(1500)
      ])
    });
    this.updateValidators();

    const rule = this.event.recurrence;
    this.recurrenceRule = rule ? rule.toRRule(this.event.dateStart) : null;
    this.subscribeToStartDateChange();
    this.editMode = !!this.event.eventId || this.type === 'update';
  }

  updateValidators(): void {
    this.eventForm?.get('dateStart')?.valueChanges.subscribe(() => {
      this.eventForm.get('dateEnd')?.updateValueAndValidity();
    });
  }

  private updateRequest(request: Observable<WorkspaceEvent>): void {
    this.updateModel();

    this.formSubmitAttempt = true;

    if (this.eventForm && this.eventForm.valid) {
      this.loadInProgress = true;

      request
        .pipe(
          first(),
          finalize(() => (this.loadInProgress = false))
        )
        .subscribe((event) => this.dialogRef.close(event));
    }
  }

  async save() {
    this.setFormDirty();

    if (!this.workspace && !this.editMode) {
      if (!this.searchWorkspaceField?.selectedWorkspace?.workspaceId) {
        this.searchWorkspaceField?.setDirty();
        return;
      }

      const workspaceId =
        this.searchWorkspaceField?.selectedWorkspace?.workspaceId;
      if (workspaceId) {
        const workspace =
          await this.activeWorkspaceService.getWorkspaceById(workspaceId);
        this.event.workspaceId = workspace.workspaceId ?? 0;
        this._save();
      }
    } else {
      this._save();
    }
  }

  private _save(): void {
    if (this.eventForm && this.eventForm.valid) {
      this.updateModel();

      if (!this.shouldSaveOnSubmit) {
        this.dialogRef.close(this.event);
        return;
      }
      if (this.isInEditMode) {
        this.recurrenceRule && this.wasAlreadyRecurring
          ? this.showUpdateRecurringEventDialog()
          : this.updateRequest(
              this.eventsService.updateEvent(this.event, this.event.eventId)
            );
      } else {
        this.updateModel();
        this.dialogRef.close(this.event);
      }
    }
  }

  updateModel() {
    const event = this.event;
    const formValue = this.eventForm.value;
    if (event && formValue) {
      event.recurrence = this.recurrenceRule
        ? RecurrenceRule.fromRRule(this.recurrenceRule)
        : null;
      event.type = formValue.type.toUpperCase();
      event.name = formValue.name;
      event.dateStart = formValue.dateStart?.utc().format();
      event.dateEnd = formValue.dateEnd?.utc().format();
      event.location = formValue.location;
      event.link = formValue.link;
      event.description = formValue.description;
      event.description = StringUtils.removeHtmlTags(
        this.event.description || ''
      );
      event.mentionedUserIds =
        this.mentionableDirective?.mentionedParticipantsIds || [];
    }
  }

  setFormDirty(): void {
    this.clearInputs();
    this.eventForm?.markAllAsTouched();
  }

  get isInEditMode(): boolean {
    return this.editMode;
  }

  /** Disable editing repeat rule for already created recurring events */
  get repeatRuleDisabled(): boolean {
    return this.isInEditMode && this.wasAlreadyRecurring;
  }

  get wasAlreadyRecurring(): boolean {
    const rule = this.originalEvent.recurrence;
    if (rule && rule.id && rule.id > 0) {
      return true;
    }
    return false;
  }

  clearInputs(): void {
    if (this.eventForm) {
      const controls = this.eventForm.controls;
      const formValue = this.eventForm.value;
      controls.type.setValue(this.formValidator.clearInput(formValue.type));
      controls.name.setValue(this.formValidator.clearInput(formValue.name));
      controls.location.setValue(
        this.formValidator.clearInput(formValue.location)
      );
      controls.link.setValue(this.formValidator.clearInput(formValue.link));
      controls.description.setValue(
        this.formValidator.clearInput(formValue.description)
      );
    }
  }

  onRepeatEventsSave(rule: RRule | null): void {
    this.recurrenceRule = rule;
    this.showRepeatEventComponent = false;
  }

  get isClientMobileSmallScreen(): boolean {
    return PlatformUtils.isClientMobileSmallScreen();
  }

  showRepeatComponent(): void {
    this.showRepeatEventComponent = true;
  }

  onRepeatEventsCancel(): void {
    this.showRepeatEventComponent = false;
  }

  private showUpdateRecurringEventDialog(): void {
    this.recurringEventsService
      .updateRecEventDialog(this.dialog)
      .then((result) => {
        if (result) {
          this.updateRequest(
            this.recurringEventsService.updateEvent(this.event, result.value)
          );
        }
      });
  }

  public get helper(): typeof RecurrenceHelper {
    return RecurrenceHelper;
  }

  private subscribeToStartDateChange(): void {
    const dateStartForm = this.eventForm?.get('dateStart');
    const dateEndForm = this.eventForm.get('dateEnd');

    dateStartForm?.valueChanges
      .pipe(startWith(dateStartForm.value), pairwise())
      .subscribe(([prev, next]) => {
        const dateEnd = dateEndForm?.value as moment.Moment;
        const duration = moment.duration(dateEnd.diff(prev));
        const newDateEnd = moment(next.toDate()).add(duration);
        dateEndForm?.setValue(newDateEnd);
        dateEndForm?.updateValueAndValidity();
        if (this.recurrenceRule) {
          RecurrenceHelper.recalculateRuleParams(next, this.recurrenceRule);
        }
      });
  }
}

export interface WorkspaceData {
  type: 'add' | 'update';
  event: WorkspaceEvent;
  workspace: Workspace;
  shouldSaveOnSubmit: boolean;
}

export class EventTypeOption {
  label: string;
  option: string;

  constructor(label: string, option: string) {
    this.label = label;
    this.option = option;
  }
}
