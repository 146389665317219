import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Attendance } from '../../models/participant';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { WorkspaceEvent } from '../../models/workspaceEvent';
import { ProfileService } from '../../services/user/profile/profile.service';
import { WorkspaceEventsService } from '../../services/events/workspace-events.service';
import { attendanceUtils } from '../attendance-label/attendance.utils';
import { NgIf } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { RecurringEventsService } from 'src/app/services/recurring-events/recurring-events.service';

@Component({
  selector: 'app-attendance-toggle',
  templateUrl: './attendance-toggle.component.html',
  styleUrls: ['./attendance-toggle.component.scss'],
  standalone: true,
  imports: [NgIf, FlexLayoutModule, MatButtonModule]
})
export class AttendanceToggleComponent implements OnInit {
  attendance = Attendance;

  @Input() userStatus: Attendance = Attendance.Unmarked;
  @Input() disabled = false;
  @Input() workspaceEvent: WorkspaceEvent | undefined;

  @Output() statusChanged = new EventEmitter<Attendance>();
  @Output() eventUpdated = new EventEmitter<WorkspaceEvent>();

  public isCanEdit = false;

  constructor(
    public dialog: MatDialog,
    private profileService: ProfileService,
    private workspaceEventService: WorkspaceEventsService,
    private recEventService: RecurringEventsService
  ) {}

  ngOnInit(): void {
    this.updateVisibility();
  }

  updateVisibility(): void {
    if (this.profileService.user?.userId && this.workspaceEvent) {
      const user = this.workspaceEventService.getOneParticipantByUserId(
        this.profileService.user.userId,
        this.workspaceEvent
      );
      this.isCanEdit = user !== undefined;
    }
  }

  onClickAttending(ev: MouseEvent): void {
    ev.stopPropagation();

    if (this.workspaceEvent && this.profileService.user?.userId) {
      this.updateAttendance(
        this.workspaceEvent,
        this.profileService.user.userId,
        Attendance.Attending
      ).then();
    }
  }

  onClickNotGoing(ev: MouseEvent): void {
    ev.stopPropagation();

    const userId = this.profileService.user?.userId;
    const event = this.workspaceEvent;

    if (event && userId) {
      const reason = this.workspaceEventService.getOneAttendanceByUserId(
        userId,
        event
      );
      const dialogRef =
        this.workspaceEventService.showRejectReasonDialog(reason);

      dialogRef.afterClosed().subscribe((result) => {
        if (result?.description || result.required === false) {
          this.updateAttendance(
            event,
            userId,
            Attendance.Rejected,
            result.description
          ).then();
        }
      });
    }
  }

  private async updateAttendance(
    event: WorkspaceEvent,
    userId: number,
    status: Attendance,
    reason: string = ''
  ): Promise<any> {
    this.userStatus = status;

    this.recEventService
      .updateAttendance(event, [userId], status, reason)
      .then((newEvent) => {
        this.eventUpdated.emit(newEvent);
        this.statusChanged.emit(this.userStatus);
      });
  }

  stringForStatus(attendance: Attendance): string {
    return attendanceUtils.attendanceLabel[attendance];
  }
}
