export enum LSKeys {
  ANNOUNCEMENTS_SEEN = 'seen_announcements',
  PUSH_NOTIFICATIONS_ENABLED = 'push_notifications_enabled',
  HWID = 'hwid',
  AUTH_TOKEN = 'token',
  TUTORIAL_SHOWSPACE_SEEN = 'seenShowspaceTutorial',
  TUTORIAL_SCHEDULE_EVENTS_SEEN = 'seenScheduleEventsTutorial',
  TUTORIAL_ADD_PARTICIPANTS_SEEN = 'seenAddParticipantsTutorial',
  CREATED_FIRST_WORKSPACE = 'createdFirstWorkspace',
  AUTH_USER_UUID = 'auth_user_uuid'
}
