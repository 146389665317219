<div fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="center stretch">
  <div class="title">{{ title }}</div>

  <mat-radio-group
    labelPosition="after"
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="18px"
    [(ngModel)]="selectedOption"
  >
    <mat-radio-button
      class="option"
      *ngFor="let option of options"
      [value]="option"
    >
      {{ option.label }}
    </mat-radio-button>
  </mat-radio-group>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <button
      mat-flat-button
      class="btn upper sm"
      color="primary"
      mat-dialog-close
    >
      Cancel
    </button>
    <button
      mat-flat-button
      class="btn upper sm"
      color="accent"
      (click)="confirmClicked()"
    >
      Confirm
    </button>
  </div>
</div>
