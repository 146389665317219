import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { EventBusNames, EventBusService } from '../event-bus/event-bus.service';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';
import { SharedService } from '../shared/shared.service';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  from,
  lastValueFrom,
  map,
  mergeMap,
  Observable,
  throwError
} from 'rxjs';
import { StorageService } from '../storage/storage.service';
import * as debug from 'debug';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Auth,
  User as FbUser,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  updatePassword
} from '@angular/fire/auth';
import { User } from 'src/app/models/user';
import {
  AuthErrorMessage,
  AuthStatus,
  AuthStatusResponse,
  AuthUtils
} from './auth.utils';

const trace = debug('apg:auth-service');

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private defaultReturnUrl = GlobalConstants.routes.workspaces;
  private syncFbUserUrl = environment.url + 'users/current/synchronize';
  private returnUrl = this.defaultReturnUrl;
  urlRegisterNewUser: string = environment.url + 'users/register';

  public authState$: Observable<FbUser | null>;
  public authState: FbUser | null = null;

  private delayAuthState$ = new BehaviorSubject(false);

  constructor(
    private httpClient: HttpClient,
    private eventBusService: EventBusService,
    private sharedService: SharedService,
    private storage: StorageService,
    private auth: Auth,
    private router: Router
  ) {
    this.authState$ = combineLatest([
      this.delayAuthState$,
      new Observable<FbUser | null>((observer) => {
        return onAuthStateChanged(this.auth, observer);
      })
    ]).pipe(
      map(([isRegistering, user]) => {
        trace('ISREG:', isRegistering, user);
        return isRegistering === true ? null : user;
      })
    );

    this.authState$
      .pipe(untilDestroyed(this))
      .subscribe((state) => (this.authState = state));
  }

  getReturnUrl(): string {
    return this.returnUrl;
  }

  resetReturnUrl(): string {
    return this.setReturnUrl(this.defaultReturnUrl);
  }

  setReturnUrl(returnUrl?: string): string {
    return (this.returnUrl = returnUrl || this.returnUrl);
  }

  logout(): void {
    this.eventBusService.emit({ name: EventBusNames.logout });
    // TODO: check if window.location.reload() is really needed here
    this.logoutUser().subscribe(() => {
      window.location.reload();
    });
  }

  navigateAfterLogin() {
    this.router
      .navigate([this.getReturnUrl()], { replaceUrl: true })
      .then()
      .finally(() => {
        this.sharedService.stopLoading();
        this.resetReturnUrl();
      });
  }

  signUp(data: NewUserData): Observable<User> {
    this.delayAuthState$.next(true);
    return from(
      createUserWithEmailAndPassword(this.auth, data.email, data.password)
    ).pipe(
      catchError((error) =>
        throwError(() => AuthUtils.parseFirebaseError(error))
      ),
      mergeMap((user) =>
        this.registerNewUser({
          name: data.name,
          surname: data.surname,
          firebaseUid: user.user?.uid ?? ''
        })
      )
    );
  }

  initPasswordReset(): Observable<string> {
    const email = this.auth.currentUser?.email;
    if (email) {
      return from(sendPasswordResetEmail(this.auth, email)).pipe(
        map(() => email)
      );
    } else {
      return throwError(() => AuthErrorMessage.General);
    }
  }

  resetPassword(oldPassword: string, newPassword: string): Observable<void> {
    const email = this.auth.currentUser?.email;
    if (email) {
      return from(
        signInWithEmailAndPassword(this.auth, email, oldPassword)
      ).pipe(
        catchError((error) =>
          throwError(() => AuthUtils.parseFirebaseError(error))
        ),
        mergeMap(() => {
          if (this.auth.currentUser) {
            return from(updatePassword(this.auth.currentUser, newPassword));
          } else {
            return throwError(() => AuthErrorMessage.General);
          }
        })
      );
    } else {
      return throwError(() => AuthErrorMessage.General);
    }
  }

  completeSignUp() {
    this.delayAuthState$.next(false);
  }

  async signIn(email: string, password: string): Promise<AuthStatus> {
    try {
      this.delayAuthState$.next(true);
      await signInWithEmailAndPassword(this.auth, email, password);
      await lastValueFrom(this.syncFbUser());
      this.delayAuthState$.next(false);

      return AuthStatus.OK;
    } catch (firstError) {
      try {
        const status = await lastValueFrom(this.validateLogin(email));
        switch (status) {
          case AuthStatus.CREDENTIALS_EXPIRED:
            await signOut(this.auth);
            await lastValueFrom(this.requestPasswordReset(email));
            return AuthStatus.CREDENTIALS_EXPIRED;
  
          case AuthStatus.DISABLED:
            await signOut(this.auth);
            return AuthStatus.DISABLED;
  
          case AuthStatus.OK:
            throw AuthUtils.parseFirebaseError(firstError);
        }
      } catch {
        throw AuthUtils.parseFirebaseError(firstError);
      }
    }
  }

  requestPasswordReset(email: string): Observable<void> {
    return from(sendPasswordResetEmail(this.auth, email));
  }

  confirmPasswordReset(code: string, newPassword: string): Observable<void> {
    return from(confirmPasswordReset(this.auth, code, newPassword)).pipe(
      catchError((error) =>
        throwError(() => AuthUtils.parseFirebaseError(error))
      )
    );
  }

  private validateLogin(email: string): Observable<AuthStatus> {
    return this.httpClient
      .get<AuthStatusResponse>(environment.url + 'users/status', {
        params: { email }
      })
      .pipe(map((response) => response.status));
  }

  private logoutUser(): Observable<void> {
    trace('logout');
    return from(signOut(this.auth));
  }

  private syncFbUser(): Observable<void> {
    trace('sync current user');
    return this.httpClient.post<void>(this.syncFbUserUrl, {});
  }

  private registerNewUser(
    data: Omit<NewUserData, 'password' | 'email'>
  ): Observable<User> {
    return this.httpClient.post<User>(this.urlRegisterNewUser, data);
  }
}

interface NewUserData {
  name: string;
  surname: string;
  firebaseUid?: string;
  email: string;
  password: string;
}
