import {
  NGX_MAT_DATE_FORMATS,
  NGX_MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
  NgxMatDateFormats
} from '@angular-material-components/datetime-picker';
import {
  Component,
  ComponentRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Priority, TaskModel } from 'src/app/models/task.model';
import { TaskService } from 'src/app/services/task/task.service';
import { SearchResultModel } from 'src/app/services/workspace/workspace.service';
import { AssigneeSearchComponent } from './assignee/assignee-search.component';
import { ShowspaceSearchComponent } from './showspace/showspace-search.component';
import { MatDatepicker } from '@angular/material/datepicker';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'MM/dd/yyyy '
  },
  display: {
    dateInput: 'MMMM D YYYY h:mm',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-task-create',
  templateUrl: './task-create.component.html',
  styleUrls: ['./task-create.component.scss'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    NGX_MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER
  ]
})
export class TaskCreateComponent implements OnInit {
  @ViewChild('dueDatePicker') datePicker: MatDatepicker<Date> | undefined;
  @ViewChild('nextContent', { read: ViewContainerRef })
  content!: ViewContainerRef;

  @Output()
  shouldUpdateTaskList = new EventEmitter<boolean>();

  formSubmitAttempt = false;
  showspacePreSelected = false;
  selectedShowspaceId: number | undefined;

  private editMode = false;
  private taskId: number | undefined;
  loadInProgress = false;
  nextStepComponentRef:
    | ComponentRef<ShowspaceSearchComponent | AssigneeSearchComponent>
    | undefined;

  public taskCreateForm: FormGroup = new FormGroup({});

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly taskService: TaskService,
    private readonly dialogRef: MatDialogRef<TaskCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public taskData: TaskModel
  ) {}

  ngOnInit(): void {
    this.showspacePreSelected = !!this.taskData.workspaceId;
    this.selectedShowspaceId = this.taskData?.workspaceId;
    this.taskId = this.taskData?.id;
    this.editMode = !!this.taskId;
    this.createForm(this.taskData);
  }

  get isInEditMode(): boolean {
    return this.editMode;
  }

  private createForm(formData: TaskModel): void {
    const showspaceData = formData?.workspaceId
      ? ({
          title: formData?.showspace,
          workspaceId: formData?.workspaceId
        } as SearchResultModel)
      : undefined;

    this.taskCreateForm = this.formBuilder.group({
      taskTitle: [
        formData?.title?.trim(),
        [
          Validators.required,
          this.noTrailingSpacesFn,
          Validators.maxLength(255)
        ]
      ],
      description: [formData?.description?.trim(), Validators.maxLength(1500)],
      showspace: [
        { value: showspaceData, disabled: this.editMode },
        Validators.required
      ],
      assignees: [{ value: [], disabled: this.editMode }],
      dueDate: [
        formData?.dueDate ? moment.utc(formData?.dueDate).local() : null,
        Validators.required
      ],
      priority: [
        formData?.priority ? formData.priority : Priority.NONE,
        Validators.required
      ]
    });
  }

  onNextPriority(): void {
    const prioritySelected: number = this.priority?.value;

    if (Priority[prioritySelected]) {
      let nextPriority = prioritySelected + 1;

      if (!Priority[nextPriority]) {
        nextPriority = 0;
      }

      this.taskCreateForm.get('priority')?.setValue(nextPriority);
    }
  }

  saveTaskForm(): void {
    this.saveOrUpdateTaskForm(
      this.taskService.createNewTask(this.toPublicApi())
    );
  }

  updateTaskForm(): void {
    this.saveOrUpdateTaskForm(this.taskService.updateTask(this.toPublicApi()));
  }

  private saveOrUpdateTaskForm(callback: Observable<TaskModel>): void {
    this.formSubmitAttempt = true;

    if (this.taskCreateForm.valid) {
      this.loadInProgress = true;

      callback.pipe(first()).subscribe({
        next: () => this.dialogRef.close(true),
        error: () => (this.loadInProgress = false)
      });
    }
  }

  private toPublicApi(): TaskModel {
    return {
      id: this.taskId,
      title: this.taskTitle?.value?.trim(),
      description: this.description?.value?.trim(),
      dueDate: this.dueDate?.value,
      showspace: this.showspace?.value?.title,
      assignees: this.assignees?.value,
      priority: Priority[this.priority?.value as keyof typeof Priority],
      isOpen: this.taskData?.isOpen,
      workspaceId: this.showspace?.value?.workspaceId,
      canManage: false
    };
  }

  public get taskTitle(): AbstractControl | null {
    return this.taskCreateForm.get('taskTitle');
  }

  public get description(): AbstractControl | null {
    return this.taskCreateForm.get('description');
  }

  public get dueDate(): AbstractControl | null {
    return this.taskCreateForm.get('dueDate');
  }

  public get priority(): AbstractControl | null {
    return this.taskCreateForm.get('priority');
  }

  public get priorityClass(): string {
    return Priority[this.priority?.value].toLocaleLowerCase();
  }

  public get showspace(): AbstractControl | null {
    return this.taskCreateForm.get('showspace');
  }

  public get assignees(): AbstractControl | null {
    return this.taskCreateForm.get('assignees');
  }

  public get priorityButtonNaming(): string {
    switch (Priority[this.priority?.value]) {
      case Priority[Priority.LOW]:
        return 'Low';
      case Priority[Priority.MEDIUM]:
        return 'Medium';
      case Priority[Priority.HIGH]:
        return 'High';
      case Priority[Priority.NONE]:
        return 'No priority';
    }

    return 'No priority';
  }

  removeComponent(): void {
    this.nextStepComponentRef?.destroy();
    this.nextStepComponentRef = undefined;
  }

  showAssigneeSearchComponent(): void {
    this.nextStepComponentRef = this.content.createComponent(
      AssigneeSearchComponent
    );
    const component = this.nextStepComponentRef
      .instance as AssigneeSearchComponent;

    component.workspaceId = this.selectedShowspaceId ?? 0;
    component.assigneeList = this.assignees?.value;

    component.selectedAssignees.subscribe((assignees) => {
      this.removeComponent();
      this.assignees?.setValue([...assignees]);
    });
  }

  showShowspaceSearchComponent(): void {
    this.showspace?.markAsTouched();
    this.nextStepComponentRef = this.content.createComponent(
      ShowspaceSearchComponent
    );

    (
      this.nextStepComponentRef.instance as ShowspaceSearchComponent
    ).selectedWorkspace.subscribe((showspace) => {
      this.removeComponent();

      if (!!showspace) {
        this.showspace?.setValue(showspace);
        this.selectedShowspaceId = showspace.workspaceId;
        this.assignees?.setValue([]);
      }
    });
  }

  openDatePicker(): void {
    // Workaround for the issue https://github.com/h2qutc/angular-material-components/issues/356
    if (!this.dueDate?.value) {
      this.dueDate?.setValue(new Date());
    }
    this.datePicker?.open();
  }

  private noTrailingSpacesFn(control: FormControl): ValidationErrors | null {
    if (control?.value?.trim().length === 0) {
      return { required: true };
    }

    return null;
  }

  shouldDisplayErrorBlock(input: AbstractControl | null): boolean {
    if (input) {
      return input.invalid && this.formSubmitAttempt;
    }
    return false;
  }

  onChange(event: any): void {
    event.target.value = event.target.value.trim();
  }
}
