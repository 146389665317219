import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { RRuleSet } from 'rrule';
import { WorkspaceEvent } from 'src/app/models/workspaceEvent';
import * as debug from 'debug';

const trace = debug('apg:recurrence-service');

@Injectable({
  providedIn: 'root',
})
export class RecurrenceService {
  constructor() {}

  /** Generate and return recurrences of all provided events for one date
   * @param useDateBoundaries defines whether first and last events dates should limit the range of generated recurrences.
   * If set to false - all recurrences will be generated
   */
  recurrencesFor(events: WorkspaceEvent[], useDateBoundaries: boolean = true): WorkspaceEvent[] {
    if (!events.length) {
      return events;
    }

    trace('Generating recurrences for events:', events);

    if (useDateBoundaries) {
      const startStr = events[0].dateStart;
      const endSrt = events[events.length - 1].dateStart;

      if (startStr && endSrt) {
        const start = new Date(startStr);
        const end = new Date(endSrt);
        return this._recurrencesFor(events, start, end);
      }
      return [];
    }
    return this._recurrencesFor(events);
  }

  /** Generate and return recurrences of all provided events for one date */
  recurrencesForDate(events: WorkspaceEvent[], date: Date): WorkspaceEvent[] {
    if (!events.length) {
      return events;
    }
    const startDate = moment(date).startOf('day').toDate();
    const endDate = moment(startDate).endOf('day').toDate();
    return this._recurrencesFor(events, startDate, endDate);
  }

  isDateRecurrenceValid(event: WorkspaceEvent, date: Date): boolean {
    const rule = event.recurrence?.toRRule(event.dateStart);
    const recurrences = rule?.between(date, date, true) ?? [];
    return recurrences.map((r) => r.getTime()).includes(date.getTime());
  }

  private _recurrencesFor(
    events: WorkspaceEvent[],
    startDate: Date | null = null,
    endDate: Date | null = null
  ): WorkspaceEvent[] {
    if (!events.length) {
      return events;
    }

    trace('Generating recurrences for date range:', startDate, endDate);

    // Store cancelled and child events that should not generate the recurrences
    const exDatesMap = this.getExclusionDates(events);

    trace('Excluding dates:', exDatesMap);

    const output = [...events];

    events.forEach((event) => {
      if (!event.isBase) {
        return;
      }

      const exDates = exDatesMap.get(event.eventId) ?? [];
      const recurrenceDates = this.recurrenceDatesFor(event, startDate, endDate, exDates);

      // if (recurrenceDates.length) {
      //   if (recurrenceDates[0].getTime() === moment.utc(event.dateStart).toDate().getTime()) {
      //       recurrenceDates.shift();
      //   }
      // }

      trace('Recurrence dates for event', event.name, ': ---', recurrenceDates);

      recurrenceDates.forEach((date) => {
        const copy = this.copyRecurringEvent(event, date);
        if (copy) {
          output.push(copy);
        }
      });
    });

    trace('Generated events:', output);
    return output;
  }

  private recurrenceDatesFor(
    event: WorkspaceEvent,
    startDate: Date | null,
    endDate: Date | null,
    exDates: string[] = []
  ): Date[] {
    const rule = event.recurrence?.toRRule(event.dateStart);

    trace('RULE for event: ', event.name, '---->', rule?.toString());

    if (!rule) {
      return [];
    }

    const ruleSet = new RRuleSet();
    ruleSet.rrule(rule);
    exDates.forEach((date) => ruleSet.exdate(moment.utc(date).toDate()));

    if (startDate && endDate) {
      return ruleSet.between(startDate, endDate, true);
    } else {
      return ruleSet.all();
    }
  }

  /** Exclusion dates returned for each cancelled event or event recurrence that was modified (known as a child) */
  private getExclusionDates(events: WorkspaceEvent[]): Map<number, string[]> {
    return events.reduce((result, event) => {
      if (event.dateStart && event.baseId && (event.isCancelled || event.isChild)) {
        const dates = result.get(event.baseId) ?? [];
        dates.push(event.dateStart);
        result.set(event.baseId, dates);
        return result;
      }
      return result;
    }, new Map<number, string[]>());
  }

  /** Create a copy of an event for displaying as virtual recurrence
   * @param event event to create a copy from
   * @param startDate event recurrence start date. Event's end date will be calculated from original event duration
   */
  private copyRecurringEvent(event: WorkspaceEvent, startDate: Date): WorkspaceEvent | null {
    if (event.dateEnd && event.dateStart) {
      const copy = new WorkspaceEvent(event.workspaceId).deserialize(event);
      const duration = new Date(event.dateEnd).getTime() - new Date(event.dateStart).getTime();
      copy.dateStart = startDate.toISOString();
      copy.dateEnd = new Date(startDate.getTime() + duration).toISOString();
      copy.eventId = 0;
      copy.baseId = event.eventId;
      copy.participants = event.participants.map((p) => {
        p.eventId = 0;
        return p;
      });
      return copy;
    }
    return null;
  }
}
