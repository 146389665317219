<ng-container [formGroup]="form">
  <div class="titleBlock">
    <h1 class="apg-title">
      {{ showCustomOption ? 'Custom recurrence' : 'Repeat' }}
    </h1>
  </div>
  <mat-dialog-content>
    <div class="apg-form-group repeat-selection">
      <ng-container *ngIf="showCustomOption">
        <label>Repeat every</label>
        <p-inputNumber
          mat-input
          styleClass="interval-input"
          #repeatTimes
          [(ngModel)]="interval"
          [ngModelOptions]="{standalone: true}"
          [showButtons]="true"
          mode="decimal"
          [min]="1"
          [max]="1000"
          [step]="1"
        ></p-inputNumber>
      </ng-container>

      <ng-container>
        <mat-selection-list
          disableRipple="true"
          *ngIf="!showCustomOption"
          class="apg-mat-select mat-select-custom-icon"
          multiple="false"
          (selectionChange)="onSelectPresetOccurence($event)"
        >
          <mat-list-option
            [selected]="frequency === null && !isRuleCustomized"
            #option
            class="custom-option"
            checkboxPosition="after"
            [value]="null"
          >
            <ng-container
              [ngTemplateOutlet]="customListOption"
              [ngTemplateOutletContext]="{
                title: 'Does not repeat', 
                selected: option.selected
              }"
            ></ng-container>
          </mat-list-option>
          <mat-list-option
            #optionWeek
            class="custom-option"
            checkboxPosition="after"
            [value]="preset"
            [selected]="frequency === preset && !isRuleCustomized"
            *ngFor="let preset of presetOptions"
          >
            <ng-container
              [ngTemplateOutlet]="customListOption"
              [ngTemplateOutletContext]="{
                title: helper.presetTitleForFrequency(preset), 
                selected: optionWeek.selected
              }"
            ></ng-container>
          </mat-list-option>
        </mat-selection-list>

        <mat-select
          *ngIf="showCustomOption"
          [(value)]="frequency"
          class="custom-select-input"
          panelClass="apg-mat-select"
        >
          <mat-option *ngFor="let option of presetOptions" [value]="option"
            >{{ helper.toUnitsString(option) }}</mat-option
          >
        </mat-select>
      </ng-container>
    </div>

    <div *ngIf="!showCustomOption" class="apg-form-group">
      <button mat-button class="custom-button" (click)="showCustomClicked()">
        <mat-icon>arrow_forward_ios</mat-icon>
        <span>Custom</span>
      </button>
    </div>

    <div
      *ngIf="showCustomOption && frequency === freq.WEEKLY"
      class="apg-form-group"
    >
      <label
        >Repeat on
        <mat-chip-list [(value)]="onWeekDays" #weekdaysList selectable multiple>
          <mat-chip
            style="cursor: pointer"
            #chip="matChip"
            *ngFor="let weekday of weekDays"
            (click)="weekdayToggled(chip)"
            [value]="weekday.index"
          >
            {{ weekday.short }}
          </mat-chip>
        </mat-chip-list>
      </label>
    </div>

    <div
      *ngIf="showCustomOption && frequency === freq.MONTHLY"
      class="apg-form-group"
    >
      <label
        >Repeat on
        <mat-chip-list
          [(value)]="onMonthDays"
          #monthdaysList
          selectable
          multiple
        >
          <mat-chip
            style="cursor: pointer"
            #chip="matChip"
            *ngFor="let monthday of monthDays"
            (click)="monthdayToggled(chip)"
            [value]="monthday"
          >
            {{ monthday }}
          </mat-chip>
        </mat-chip-list>
      </label>
    </div>

    <div class="apg-form-group apg-col-6">
      <label
        >Ends
        <input
          type="text"
          [max]="maxEndRepeatDate"
          class="calendar-input"
          [ngxMatDatetimePicker]="picker2"
          placeholder="Date Time"
          formControlName="repeatEnds"
          readonly="readonly"
          (keydown)="$event.preventDefault()"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="$any(picker2)"
          [disableRipple]="true"
        ></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #picker2
          [hideTime]="true"
          [enableMeridian]="true"
        ></ngx-mat-datetime-picker>
      </label>
    </div>

    <div class="apg-form-group" fxLayout="row" fxLayoutGap="20px">
      <button mat-flat-button class="btn upper secondary" (click)="onCancel()">
        Cancel
      </button>
      <button
        mat-flat-button
        color="accent"
        class="btn upper"
        (click)="onSave()"
      >
        Done
      </button>
    </div>
  </mat-dialog-content>
</ng-container>

<ng-template #customListOption let-title="title" let-selected="selected">
  <div class="custom-list-option" [class.option-selected]="selected">
    <span class="checked-icon material-icons">
      <mat-icon
        [svgIcon]="selected ? 'checked_circle' : 'unchecked_circle'"
      ></mat-icon>
    </span>
    <span>{{ title }}</span>
  </div>
</ng-template>
