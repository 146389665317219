import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  PageableSearchList,
  SearchResultModel,
  WorkspaceService,
} from '../../../services/workspace/workspace.service';
import { Observable, timer } from 'rxjs';
import { debounce, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-search-workspace-field',
  templateUrl: './search-workspace-field.component.html',
  styleUrls: ['./search-workspace-field.component.scss'],
})
export class SearchWorkspaceFieldComponent implements OnInit, OnDestroy {

  @Input() selectedWorkspace: SearchResultModel | null = { title: undefined };
  @Output() selectedWorkspaceChange = new EventEmitter<SearchResultModel | null>();

  availableWorkspaces: PageableSearchList | null = null;

  private limit = 50;
  private page = 0;
  private lastSearchKeyword: string | null = null;
  private dirtyInput = false;

  private searchWorkspacesEvent$: EventEmitter<string> = new EventEmitter<string>();

  constructor(private workspaceService: WorkspaceService) {}

  ngOnInit(): void {
    this.searchWorkspacesEvent$
      .pipe(
        debounce(() => timer(500)),
        distinctUntilChanged(),
        switchMap((keyword: string) => this._searchWorkspaces(keyword, this.limit, this.page))
      )
      .subscribe((res: PageableSearchList | undefined) => {
        if (res) {
          this.availableWorkspaces = res;
        }
      });

    this.searchWorkspacesEvent$.emit('');
  }

  ngOnDestroy(): void {
    this.searchWorkspacesEvent$.complete();
  }

  onChangeEvent(keyword: any): void {
    this.availableWorkspaces = null;
    this.page = 0;
    this.dirtyInput = true;
    this.searchWorkspacesEvent$.emit(keyword);
  }

  onClearSearch($event: any): void {
    this.page = 0;
    this.lastSearchKeyword = null;
    this.searchWorkspacesEvent$.emit('');
    this.onWorkspaceSelected(null);
  }

  loadMoreSearchResults(): void {
    if (this.lastSearchKeyword != null) {
      this._searchWorkspaces(this.lastSearchKeyword, this.limit, ++this.page).subscribe(
        (res: PageableSearchList | undefined) => {
          if (res && this.availableWorkspaces) {
            for (const workspace of res.content) {
              this.availableWorkspaces.content.push(workspace);
            }

            this.availableWorkspaces.hasNext = res.hasNext;
          }
        }
      );
    }
  }

  onWorkspaceSelected(workspace: SearchResultModel | null): void {
    this.selectedWorkspace = workspace;
    this.selectedWorkspaceChange.emit(workspace);
  }

  setDirty(): void {
    this.dirtyInput = true;
  }

  get getSelectedWorkspace(): SearchResultModel | null {
    return this.selectedWorkspace;
  }

  private _searchWorkspaces(
    keyword: string,
    limit: number,
    page: number
  ): Observable<PageableSearchList | undefined> {
    this.lastSearchKeyword = keyword;
    return this.workspaceService.searchForWorkspaces(keyword, limit, page);
  }

  get invalid(): boolean {
    return this.dirtyInput && (!this.selectedWorkspace || !this.selectedWorkspace.workspaceId);
  }

  getWorkspaceTitle(item: SearchResultModel): string {
    return item?.title || '';
  }
}
