import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { lastValueFrom, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import {
  MultipleChoiceDialogModel,
  MultipleChoiceDialogComponent
} from 'src/app/dialog/multiple-choice-dialog/multiple-choice-dialog.component';
import { AttendanceObject } from 'src/app/models/AttendanceObject';
import { Attendance } from 'src/app/models/participant';
import { WorkspaceEvent } from 'src/app/models/workspaceEvent';
import { environment } from 'src/environments/environment';
import { WorkspaceEventsService } from '../events/workspace-events.service';

@Injectable({
  providedIn: 'root'
})
export class RecurringEventsService {
  constructor(
    private eventsService: WorkspaceEventsService,
    private http: HttpClient
  ) {}

  deleteEvent(
    event: WorkspaceEvent,
    option: RecEventActionType
  ): Observable<any> {
    switch (option) {
      case 'THIS':
        return this.deleteOneEvent(event);
      case 'ALL':
        return this.deleteAllOccurences(event);
      case 'FUTURE':
        return this.deleteThisAndFuture(event);
    }
  }

  updateEvent(
    event: WorkspaceEvent,
    option: RecEventActionType
  ): Observable<any> {
    switch (option) {
      case 'THIS':
        return this.updateOneEvent(event);
      case 'ALL':
        return this.updateAllOccurences(event);
      case 'FUTURE':
        return this.updateThisAndFuture(event);
    }
  }

  updateParticipantsList(
    event: WorkspaceEvent,
    userIds: number[],
    action: 'add' | 'remove',
    option: RecEventActionType
  ): Observable<WorkspaceEvent> {
    switch (option) {
      case 'THIS':
        return this.updateUsersListForOneEvent(event, userIds, action);
      case 'ALL':
        return this.updateUsersListForAllEvents(event, userIds, action);
      case 'FUTURE':
        return this.updateUsersListForThisAndFutureEvents(
          event,
          userIds,
          action
        );
    }
  }

  // Update methods

  private updateOneEvent(event: WorkspaceEvent): Observable<WorkspaceEvent> {
    return event.isVirtual
      ? this.createChildEvent(event)
      : this.eventsService.updateEvent(event, event.eventId);
  }

  private updateAllOccurences(event: WorkspaceEvent): Observable<any> {
    return this.eventsService.updateEvent(event, event.baseId ?? event.eventId);
  }

  private updateThisAndFuture(event: WorkspaceEvent): Observable<any> {
    const startDate = event.dateStart;
    const newEndRepeat = moment.utc(startDate).subtract(1, 'second').toDate();
    return this.deleteFutureEventsOf(event).pipe(
      mergeMap(() => this.updateBaseEventRepeatEnd(event, newEndRepeat)),
      mergeMap(() => this.eventsService.createEvent(event))
    );
  }

  // Delete methods

  private deleteThisAndFuture(event: WorkspaceEvent): Observable<any> {
    const startDate = event.dateStart;
    const newEndRepeat = moment.utc(startDate).subtract(1, 'second').toDate();
    return this.updateBaseEventRepeatEnd(event, newEndRepeat).pipe(
      mergeMap((ev) => this.deleteFutureEventsOf(ev))
    );
  }

  private deleteAllOccurences(event: WorkspaceEvent): Observable<any> {
    const id = event.isNormal ? event.eventId : (event.baseId ?? -1);
    return this.eventsService.deleteEventById(id, event.workspaceId);
  }

  private deleteOneEvent(event: WorkspaceEvent): Observable<any> {
    if (event.isChild) {
      event.status = 'CANCELLED';
      return this.eventsService.updateEvent(event, event.eventId);
    } else if (event.isVirtual) {
      event.status = 'CANCELLED';
      return this.createChildEvent(event);
    } else {
      throw Error('Invalid delete operation');
    }
  }

  // Attendance

  async updateAttendance(
    event: WorkspaceEvent,
    userIds: number[],
    status: Attendance,
    reason: string = ''
  ): Promise<WorkspaceEvent> {
    let eventId = event.eventId;
    let result: WorkspaceEvent = event;

    if (event.isVirtual) {
      const child = await this.createChild(event);
      result = new WorkspaceEvent(child.workspaceId).deserialize(child);
      eventId = result.eventId;
    }

    const attendances = userIds.map((userId) => {
      return new AttendanceObject(
        status,
        userId,
        eventId,
        this.eventsService.getActualFromEvent(event),
        reason
      );
    });

    return this.eventsService
      .updateAttendances(event.workspaceId, attendances)
      .then(() => result);
  }

  // Update participants list

  private updateUsersListForOneEvent(
    event: WorkspaceEvent,
    userIds: number[],
    action: 'add' | 'remove'
  ): Observable<WorkspaceEvent> {
    if (event.isVirtual) {
      return this.createChildEvent(event).pipe(
        mergeMap((child) =>
          this.eventsService
            .updateUsersListRequest(
              child.eventId,
              child.workspaceId,
              userIds,
              action
            )
            .pipe(map(() => child))
        )
      );
    }
    return this.eventsService
      .updateUsersListRequest(event.eventId, event.workspaceId, userIds, action)
      .pipe(map(() => event));
  }

  updateUsersListForAllEvents(
    event: WorkspaceEvent,
    userIds: number[],
    action: 'add' | 'remove'
  ): Observable<WorkspaceEvent> {
    const eventId = event.baseId ?? event.eventId;
    return this.eventsService
      .updateUsersListRequest(eventId, event.workspaceId, userIds, action)
      .pipe(map(() => event));
  }

  updateUsersListForThisAndFutureEvents(
    event: WorkspaceEvent,
    userIds: number[],
    action: 'add' | 'remove'
  ): Observable<WorkspaceEvent> {
    const startDate = event.dateStart;
    const newEndRepeat = moment.utc(startDate).subtract(1, 'second').toDate();

    return this.deleteFutureEventsOf(event).pipe(
      mergeMap(() => this.updateBaseEventRepeatEnd(event, newEndRepeat)),
      mergeMap(() =>
        this.eventsService.createEvent(event, action === 'remove')
      ),
      mergeMap((newEvent) => {
        return this.eventsService
          .updateUsersListRequest(
            newEvent.eventId,
            newEvent.workspaceId,
            userIds,
            action
          )
          .pipe(map(() => newEvent));
      })
    );
  }

  updateRecEventDialog(dialog: MatDialog): Promise<any> {
    const data: MultipleChoiceDialogModel = {
      title: 'You modifying recurring event',
      options: [
        { label: 'Update all recurrences', value: 'ALL' },
        { label: 'Update only this event', value: 'THIS' },
        { label: 'Update this and future recurrences', value: 'FUTURE' }
      ]
    };

    return new Promise((resolve) => {
      dialog
        .open(MultipleChoiceDialogComponent, {
          panelClass: 'confirm-dialog-container',
          data
        })
        .afterClosed()
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  private deleteFutureEventsOf(event: WorkspaceEvent): Observable<any> {
    const params = new HttpParams().set('startDate', event.dateStart ?? '');
    return this.http.post(this.deleteFutureEventsURL(event), {}, { params });
  }

  createChild(event: WorkspaceEvent): Promise<WorkspaceEvent> {
    return lastValueFrom(
      this.http.post<WorkspaceEvent>(this.createChildEventURL(event), event)
    );
  }

  createChildEvent(event: WorkspaceEvent): Observable<WorkspaceEvent> {
    return this.http
      .post<WorkspaceEvent>(this.createChildEventURL(event), event)
      .pipe(
        map((response) => {
          return new WorkspaceEvent(event.workspaceId).deserialize(response);
        })
      );
  }

  private updateBaseEventRepeatEnd(
    event: WorkspaceEvent,
    endRepeatDate: Date
  ): Observable<WorkspaceEvent> {
    const endRepeat = { endRepeat: endRepeatDate.toISOString() };
    const body = { recurrence: endRepeat };
    return this.http
      .put<WorkspaceEvent>(this.eventURL(event), body)
      .pipe(map((response) => new WorkspaceEvent(0).deserialize(response)));
  }

  private createChildEventURL(event: WorkspaceEvent): string {
    return this.eventURL(event) + '/children';
  }

  private deleteFutureEventsURL(event: WorkspaceEvent): string {
    return this.eventURL(event) + '/children/delete';
  }

  private eventURL(event: WorkspaceEvent): string {
    return (
      environment.url + `workspaces/${event.workspaceId}/events/${event.anyId}`
    );
  }
}

export type RecEventActionType = 'ALL' | 'THIS' | 'FUTURE';
