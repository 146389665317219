import { EventParticipant } from './participant';
import { CalendarOptions } from 'datebook';
import { UtilsService } from '../services/utils/utils.service';
import * as moment from 'moment/moment';
import { RecurrenceRule } from './recurrenceRule';

const date1 = new Date();
const date0 = new Date();
date0.setHours(date0.getHours() + 1);

export enum EventTypes {
  Audition = 'AUDITION',
  Class = 'CLASS',
  Performance = 'PERFORMANCE',
  Rehearsal = 'REHEARSAL',
  Miscellaneous = 'MISCELLANEOUS'
}

export class WorkspaceEvent {
  dateEnd: string | undefined = date0.toISOString();
  dateStart: string | undefined = date1.toISOString();
  workspaceTitle: string | undefined;
  description: string | null | undefined;
  enabled = true;
  eventId = 0;
  link: string | null | undefined;
  location: string | null | undefined;
  name = '';
  participants: EventParticipant[] = [];
  fileLink: string | undefined;
  inviterId: number | undefined;
  workspaceId = 0;
  pictures = '';
  type: EventTypes = EventTypes.Audition;
  mentionedUserIds: Array<number> = [];

  ticketCapacity = 0;
  ticketCost = 0;
  ticketAvailability = 0;
  ticketCurrency = 'usd';
  ticketReserved = 0;

  recurrence: RecurrenceRule | null = null;
  baseId: number | null = null;
  status: 'CONFIRMED' | 'CANCELLED' = 'CONFIRMED';

  constructor(workspaceId: number) {
    this.workspaceId = workspaceId;
  }

  get isReadyForSale(): boolean {
    return this.ticketCost > 0 && this.ticketCapacity > 0;
  }

  get ticketsAvailable(): number {
    const availability = this.ticketAvailability - this.ticketReserved;
    return availability < 0 ? 0 : availability;
  }

  get isPastEvent(): boolean {
    const date = this.dateEnd ?? this.dateStart;
    return date ? moment.utc(date).local().toDate() < new Date() : false;
  }

  get isNotStarted(): boolean {
    if (this.dateStart) {
      return new Date().getTime() < new Date(this.dateStart).getTime();
    }
    return false;
  }

  get isRecurring(): boolean {
    return this.recurrence !== null;
  }

  get isCancelled(): boolean {
    return this.status === 'CANCELLED';
  }

  /** Returns either eventId or baseId in case this is recurring event */
  get anyId(): number | null {
    return this.eventId > 0 ? this.eventId : this.baseId;
  }

  /** Event that does not require generating recurrences */
  get isNormal(): boolean {
    return this.isBase || !this.isRecurring;
  }

  /** Base event - original object of a recurring event that has recurrence rules associated */
  get isBase(): boolean {
    return this.isRecurring && this.baseId === null;
  }

  /** Recurrence that does not exist in the database.
   * Frontend generates virtual event based on recurrence rules provided by the base event
   */
  get isVirtual(): boolean {
    return this.isRecurring && this.eventId === 0 && this.baseId !== null;
  }

  /** Event that is a modified recurrence of its base event. This recurrence exists in database */
  get isChild(): boolean {
    return this.eventId !== 0 && this.baseId !== null;
  }

  static getCalendarConfig(workspaceEvent: WorkspaceEvent): CalendarOptions {
    return {
      title: workspaceEvent.name,
      location: workspaceEvent.location || '',
      description: workspaceEvent.description || '',
      start: UtilsService.toLocalDate(workspaceEvent.dateStart),
      end: UtilsService.toLocalDate(workspaceEvent.dateEnd)
    };
  }

  // TODO: Backend should return it in the future...
  getGrossPrice(quantity: number): number {
    const applicationFee = 0.01;
    const price = this.ticketCost;
    const fee = price * applicationFee;
    const total = (price + 0.3 + fee) / (1 - 0.029);
    return (Math.round(total * 100.0) / 100.0) * quantity;
  }

  get recurrenceInfo(): string {
    return `ID: ${this.eventId}, baseID: ${this.baseId}, recurring: ${this.isRecurring}, child: ${this.isChild}, base: ${this.isBase}, virtual: ${this.isVirtual}`;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.recurrence) {
      this.recurrence = new RecurrenceRule().deserialize(input.recurrence);
    }
    if (input.participants) {
      this.participants = input.participants;
    }
    return this;
  }

  reassignEventDatesTo(newStartDate: Date): void {
    const duration =
      new Date(this.dateEnd ?? '').getTime() -
      new Date(this.dateStart ?? '').getTime();
    this.dateStart = newStartDate.toISOString();
    this.dateEnd = new Date(newStartDate.getTime() + duration).toISOString();
  }
}
