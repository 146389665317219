import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import {
  MAT_LEGACY_DIALOG_DATA,
  MatLegacyDialogModule,
  MatLegacyDialogRef
} from '@angular/material/legacy-dialog';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatLegacyDialogModule,
    MatRadioModule,
    MatLegacyButtonModule,
    FormsModule
  ],
  selector: 'app-multiple-choice-dialog',
  templateUrl: './multiple-choice-dialog.component.html',
  styleUrls: ['./multiple-choice-dialog.component.scss']
})
export class MultipleChoiceDialogComponent {
  @Input() options: MultipleChoiceDialogOption[] = [];

  @Input() title = '';

  selectedOption: MultipleChoiceDialogOption;

  constructor(
    private dialogRef: MatLegacyDialogRef<MultipleChoiceDialogComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) private data: MultipleChoiceDialogModel
  ) {
    this.title = data.title;
    this.options = data.options;
    this.selectedOption = data.options[0];
  }

  confirmClicked(): void {
    this.dialogRef.close(this.selectedOption);
  }
}

export interface MultipleChoiceDialogModel {
  title: string;
  options: MultipleChoiceDialogOption[];
}

export interface MultipleChoiceDialogOption {
  label: string;
  value: any;
}
