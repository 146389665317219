<div *ngIf="loading">
  <app-loading></app-loading>
</div>

<div
  *ngIf="!loading && events"
  class="calendar-events"
  id="calendar-events-list"
>
  <div *ngFor="let event of events.data" fxLayout="column">
    <app-event-card
      *ngIf="event.type === CalendarEventType.WORKSPACE_EVENT"
      class="workspace-event"
      [workspaceEvent]="getAsWorkspaceEvent(event.data)"
      [workspace]="createWorkspaceData(event.data)"
      [currentUserStatus]="currentUserStatusFor(event.data)"
      [showWorkspaceTitle]="true"
      [showType]="false"
      (eventUpdated)="reloadCalendar()"
      (eventDeleted)="reloadCalendar()"
    >
    </app-event-card>

    <app-task-card
      *ngIf="event.type === CalendarEventType.TASK"
      class="task-event"
      [displayStatus]="true"
      [workspace]="createWorkspaceData(event.data)"
      [taskModel]="createTaskModel(event.data)"
      (loadTasksEvent)="reloadCalendar()"
      (taskChanged)="reloadData()"
    >
    </app-task-card>
  </div>
</div>
