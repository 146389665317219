import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import {
  EventBusNames,
  EventBusService
} from '../../../services/event-bus/event-bus.service';
import { DialogTextComponent, DialogTextData } from '../../../component/dialog/dialog-text/dialog-text.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GlobalConstants } from '../../../common/global-constants';
import { TwilioService } from '../../../services/user/twilio/twilio.service';
import { ProfileService } from '../../../services/user/profile/profile.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  isVisibleAdminButtons = false;
  @Input() drawer: any;

  public production = environment.production;
  public routes = GlobalConstants.routes;
  public adminRoutes = GlobalConstants.adminRoutes;
  public i18n = GlobalConstants.i18n;
  public version: string = environment.appVersion;

  constructor(
    private eventBusService: EventBusService,
    private dialog: MatDialog,
    public twilioService: TwilioService,
    public profile: ProfileService,
    private auth: AuthService
  ) {}

  logout(): void {
    this.auth.logout();
  }

  close(): void {
    if (window.innerWidth < 960) {
      this.drawer.close();
    }
  }

  openPrivacyPolicy($event: any): void {
    $event.preventDefault();

    this.dialog.open(DialogTextComponent, {
      data: {
        title: 'Privacy Policy',
        type: 'pp'
      } satisfies DialogTextData
    });
  }

  openUserTerms($event: any): void {
    $event.preventDefault();

    this.dialog.open(DialogTextComponent, {
      data: {
        title: 'ACTOR’S POCKET GUIDE TERMS OF USE',
        type: 'terms'
      } satisfies DialogTextData
    });
  }

  adminLink(route: string): string {
    return '/' + this.adminRoutes.adminPanel + '/' + route;
  }
}
