import { Component, HostListener, Input } from '@angular/core';
import { GlobalConstants } from '../../common/global-constants';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogInfoComponent } from '../../dialog/dialog-info/dialog-info.component';
import { NgIf } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-logo-header',
  templateUrl: './logo-header.component.html',
  styleUrls: ['./logo-header.component.scss'],
  standalone: true,
  imports: [NgIf, RouterModule, FlexLayoutModule]
})
export class LogoHeaderComponent {
  constructor(private dialog: MatDialog) {}

  @Input() color = 'white';
  routes = GlobalConstants.routes;

  showInfo(): void {
    this.dialog.open(DialogInfoComponent, {
      panelClass: 'custom-dialog-container'
    });
    this.updateDialogSizes();
  }

  updateDialogSizes(): void {
    const popup = document.getElementsByClassName('custom-dialog-container')[0];

    const isMobile =
      screen.width < 400 ||
      (screen.orientation.type === 'landscape-primary' && screen.height < 400);

    const horizontalPadding = isMobile ? 16 : 40;
    const verticalPadding = isMobile ? 24 : 87;

    if (popup !== undefined) {
      // @ts-ignore
      popup.style.width =
        'calc(' + window.innerWidth + 'px - ' + horizontalPadding * 2 + 'px)';
      // @ts-ignore
      popup.style.height =
        'calc(' + window.innerHeight + 'px - ' + verticalPadding * 2 + 'px)';
      // @ts-ignore
      popup.style.maxWidth = 'none';
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(): void {
    setTimeout(() => {
      this.updateDialogSizes();
    }, 100);
  }
}
