export enum AuthErrorMessage {
  General = 'There was an error. Please try again. Email support@actorspocketguide.com if the error persists.',
  CredsExpired = 'Your password has expired. We have sent password reset link to your email. Please follow the instructions from there',
  Disabled = 'Your account was blocked. Please contact administrator for more information',
  WrongCreds = 'Your email or password is not correct',
  InvalidResetCode = 'Reset code invalid or expired. Please request a new reset password link',
  EmailAlreadyInUse = 'This email is already in use'
}

export enum AuthStatus {
  OK = 'OK',
  CREDENTIALS_EXPIRED = 'CREDENTIALS_EXPIRED',
  DISABLED = 'DISABLED'
}

function parseFirebaseError(error: any): Error {
  var errorMsg = AuthErrorMessage.General;
  if ('code' in error) {
    switch (error.code) {
      case 'auth/wrong-password':
      case 'auth/invalid-login-credentials':
        errorMsg = AuthErrorMessage.WrongCreds;
        break;
      case 'auth/invalid-action-code':
        errorMsg = AuthErrorMessage.InvalidResetCode;
        break;
      case 'auth/email-already-in-use':
        errorMsg = AuthErrorMessage.EmailAlreadyInUse;
    }
  }
  return new Error(errorMsg);
}

export interface AuthStatusResponse {
  status: AuthStatus;
}

export const AuthUtils = {
  parseFirebaseError
};
