import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { GlobalConstants } from '../common/global-constants';
import { first, lastValueFrom, map, take, tap } from 'rxjs';
import * as debug from 'debug';
import {} from '@angular/fire/auth';

const trace = debug('apg:auth-guard');

export function authGuard(): CanActivateFn {
  return async () => {
    const authService = inject(AuthService);
    const router = inject(Router);

    const state = await lastValueFrom(authService.authState$.pipe(first()));

    if (state) {
      trace('Authorized, Proceed to app');
      return true;
    } else {
      trace('Not authorized, Proceed to login');
      return router.createUrlTree([GlobalConstants.nonAuthRoutes.signin], {
        queryParams: {
          return: router.routerState.snapshot.url
        }
      });
    }
  };
}
