import {
  NgxMatDateFormats,
  NGX_MAT_DATE_FORMATS,
  NgxMatDatetimePickerModule
} from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import {
  MatLegacyChip as MatChip,
  MatLegacyChipsModule
} from '@angular/material/legacy-chips';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import {
  MatLegacyListModule,
  MatLegacySelectionListChange
} from '@angular/material/legacy-list';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import * as moment from 'moment';
import { InputNumberModule } from 'primeng/inputnumber';
import { Frequency, RRule } from 'rrule';
import { RecurrenceHelper } from 'src/app/common/recurrence-utils';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'M/D h:mm a'
  },
  display: {
    dateInput: 'M/D/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-dialog-add-update-event-repeat',
  templateUrl: './dialog-add-update-event-repeat.html',
  styleUrls: ['./dialog-add-update-event-repeat.component.scss'],
  providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatLegacyChipsModule,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
    MatLegacySelectModule,
    MatLegacyDialogModule,
    InputNumberModule,
    ReactiveFormsModule,
    FormsModule,
    MatLegacyListModule,
    MatLegacyButtonModule,
    FlexLayoutModule
  ]
})
export class DialogAddUpdateEventRepeatComponent implements OnInit {
  @Input() rule: RRule | null | undefined = null;

  @Input() startDate: string | undefined = undefined;

  @Output()
  save: EventEmitter<RRule | null> = new EventEmitter<RRule | null>();

  @Output()
  cancel: EventEmitter<void> = new EventEmitter<void>();

  // Indexes correspond to RRule byWeekday system
  readonly weekDays: WEEKDAY[] = [
    { title: 'MO', short: 'M', index: 0 },
    { title: 'TU', short: 'T', index: 1 },
    { title: 'WE', short: 'W', index: 2 },
    { title: 'TH', short: 'T', index: 3 },
    { title: 'FR', short: 'F', index: 4 },
    { title: 'SA', short: 'S', index: 5 },
    { title: 'SU', short: 'S', index: 6 }
  ];

  readonly monthDays: number[] = [...Array(31).keys()].map((i) => i + 1);

  readonly presetOptions = [
    Frequency.DAILY,
    Frequency.WEEKLY,
    Frequency.MONTHLY
  ];

  form: FormGroup = new FormGroup({});
  showCustomOption = false;

  onWeekDays: number[] = [];
  onMonthDays: number[] = [];
  onMonths: number[] = [];

  frequency: Frequency | null = null;
  interval = 1;

  maxEndRepeatDate: Date;

  constructor() {
    this.maxEndRepeatDate = moment().add(1, 'year').toDate();
  }

  ngOnInit(): void {
    this.interval = this.rule?.options.interval ?? 1;
    this.frequency = this.rule?.options.freq ?? null;
    this.onWeekDays = this.rule?.options.byweekday ?? [this.defaultWeekday];
    this.onMonthDays = this.rule?.options.bymonthday ?? [this.defaultMonthday];
    this.onMonths = this.rule?.options.bymonth ?? [this.defaultMonth];
    const repeatEnds = this.rule?.options.until ?? this.startDate;
    const repeatEndsDate = repeatEnds ? new Date(repeatEnds) : new Date();

    this.form = new FormGroup({
      repeatEnds: new FormControl(repeatEndsDate)
    });
  }

  onSelectPresetOccurence(selection: MatLegacySelectionListChange): void {
    this.resetCustomSelection();
    this.frequency = selection.options[0].value;
    this.setDefaultRule();
  }

  onSave(): void {
    if (this.frequency !== null) {
      const rule = new RRule({
        freq: this.frequency,
        interval: this.interval,
        byweekday: this.onWeekDays,
        bymonth: this.onMonths,
        bymonthday: this.onMonthDays,
        dtstart: new Date(this.startDate ?? ''),
        until: new Date(this.repeatEnds?.value ?? this.startDate)
      });
      this.save.emit(rule);
      return;
    }
    this.save.emit(null);
  }

  onCancel(): void {
    this.cancel.emit();
  }

  showCustomClicked(): void {
    this.frequency = this.frequency ?? Frequency.DAILY;
    this.showCustomOption = true;
  }

  weekdayToggled(chip: MatChip): void {
    if (chip.selected && this.onWeekDays.length === 1) {
      return;
    }
    chip.toggleSelected(true);
  }

  monthdayToggled(chip: MatChip): void {
    if (chip.selected && this.onMonthDays.length === 1) {
      return;
    }
    chip.toggleSelected(true);
  }

  get isRuleCustomized(): boolean {
    if (this.frequency && this.rule) {
      return !RecurrenceHelper.isRulePreset(this.rule);
    }
    return false;
  }

  get repeatEnds(): AbstractControl | null {
    return this.form.get('repeatEnds');
  }

  private resetCustomSelection(): void {
    this.onWeekDays = [];
    this.interval = 1;
  }

  private get defaultWeekday(): number {
    const day = moment.utc(this.startDate).day();
    // Convert moment day system to RRule
    const weekday = day === 0 ? 6 : day - 1;
    return weekday;
  }

  private get defaultMonthday(): number {
    return moment.utc(this.startDate).date();
  }

  private get defaultMonth(): number {
    // Convert moment month system to RRule
    return moment.utc(this.startDate).month() + 1;
  }

  private setDefaultRule(): void {
    if (this.frequency) {
      this.onMonthDays = [this.defaultMonthday];
      this.onMonths = [this.defaultMonth];
      this.onWeekDays = [this.defaultWeekday];
    }
  }

  // For HTML to access enum
  get freq(): typeof Frequency {
    return Frequency;
  }

  get helper(): typeof RecurrenceHelper {
    return RecurrenceHelper;
  }
}

export interface WEEKDAY {
  title: string;
  short: string;
  index: number;
}
