import { Injectable } from '@angular/core';
import { FuzzySearchService } from './fuzzy-search.service';
import { FuseResult } from 'fuse.js';

interface FuseParticipant {
  email: string;
  name?: string;
  surname?: string;
  fullName?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FilterByFuseService {
  constructor(private fuzzySearch: FuzzySearchService) {}

  filterParticipants<T extends FuseParticipant>(
    participants: T[],
    findBy: string
  ): T[] {
    return this.fuzzySearch
      .search(participants, ['email', 'name', 'surname', 'fullName'], findBy)
      .map((result: FuseResult<T>) => result.item);
  }
}
