<div
  class="card-container"
  *ngIf="workspaceEvent"
  [class.compact]="isCompact"
  [@cardFlip]="state"
  [class.past-event]="workspaceEvent.isPastEvent"
  [routerLink]="eventRouteActive ? eventRoute : null"
  [queryParams]="
    workspaceEvent.isVirtual ? { recurrence: workspaceEvent.dateStart } : {}
  "
  (click)="onClick()"
>
  <div class="back-card" [hidden]="state === 'default'">
    <img src="assets/workspaces/congrats.svg" alt="" />
    <div class="event-title congrats">See you there!</div>
  </div>

  <div
    class="front-card"
    [style.visibility]="state === 'flipped' ? 'hidden' : 'visible'"
  >
    <div class="top-row">
      <div class="event-date">
        {{
          utils.getDateRange(
            this.workspaceEvent.dateStart,
            this.workspaceEvent.dateEnd,
            dateFormat
          )
        }}
      </div>

      <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
        <mat-icon
          *ngIf="workspaceEvent.isRecurring"
          style="height: 15px; width: 15px"
          [svgIcon]="'repeat-solid'"
        ></mat-icon>

        <div class="more-block">
          <button
            class="more"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="menu">
            <ng-container *ngIf="canDelete">
              <button
                mat-menu-item
                (click)="
                  workspaceEvent.isVirtual || workspaceEvent.isChild
                    ? showDeleteDialogForRecurringEvent()
                    : showDeleteDialog()
                "
              >
                <span>Delete</span>
              </button>
            </ng-container>

            <ng-container *ngIf="canEdit">
              <button mat-menu-item (click)="showEditDialog()">
                <span>Edit</span>
              </button>
            </ng-container>

            <ng-container>
              <button mat-menu-item (click)="showReportDialog()">
                <span>Report</span>
              </button>
            </ng-container>

            <button mat-menu-item [matMenuTriggerFor]="calendar">
              <span>Add to Calendar</span>
            </button>
          </mat-menu>

          <mat-menu #calendar="matMenu" class="menu">
            <button
              *ngIf="utils.isMacOrIOS()"
              mat-menu-item
              (click)="addToICalendar()"
            >
              iCalendar
            </button>
            <button mat-menu-item (click)="addToGoogle()">
              Google calendar
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

    <div class="event-title">{{ workspaceEvent.name }}</div>
    <div class="event-type-parent" *ngIf="showType">
      <div class="event-type">({{ workspaceEvent.type }})</div>
    </div>
    <div class="event-type-parent" *ngIf="showWorkspaceTitle">
      <div class="event-type">{{ workspace?.title ?? '' }}</div>
    </div>

    <div class="event-type-parent" style="opacity: 0.5">
      <div>{{ workspaceEvent.recurrenceInfo }}</div>
    </div>

    <div class="event-type-parent" style="opacity: 0.5">
      <div>
        {{ workspaceEvent.recurrence?.frequency }}
        {{ workspaceEvent.recurrence?.frequencyRules }}
      </div>
    </div>

    <div class="bottom-row">
      <div class="attendance-status">
        <div
          *ngIf="userSetAttendance; then attendanceLabel; else attendanceToggle"
        ></div>

        <ng-template #attendanceLabel>
          <app-attendance-label
            [userStatus]="currentUserStatus"
          ></app-attendance-label>
        </ng-template>

        <ng-template #attendanceToggle>
          <app-attendance-toggle
            [disabled]="workspaceEvent.isPastEvent"
            [workspaceEvent]="workspaceEvent"
            (eventUpdated)="onEventUpdated($event)"
            (statusChanged)="onStatusChanged($event)"
          >
          </app-attendance-toggle>
        </ng-template>
      </div>

      <div class="participants-preview">
        <app-avatars-preview
          [workspaceEvent]="workspaceEvent"
        ></app-avatars-preview>
      </div>
    </div>
  </div>
</div>
