import * as moment from 'moment';
import { RRule } from 'rrule';
import { RecurrenceHelper } from '../common/recurrence-utils';

export declare type RecurrenceFrequency =
  | 'DAILY'
  | 'WEEKLY'
  | 'MONTHLY'
  | 'YEARLY';

export class RecurrenceRule {
  frequency: RecurrenceFrequency = 'DAILY';
  interval = 1;
  endRepeat: string | undefined;
  frequencyRules: number[] = [];
  id: number | null = null;

  constructor() {}

  static fromRRule(rrule: RRule): RecurrenceRule {
    const rule = new RecurrenceRule();
    rule.frequency = RecurrenceHelper.toRecurrenceFrequency(rrule.options.freq);
    rule.frequencyRules = RecurrenceHelper.toRecurrenceRules(rrule);
    rule.interval = rrule.options.interval;
    rule.endRepeat = rrule.options.until?.toISOString();
    return rule;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  toRRule(dateStart: string | undefined): RRule {
    return new RRule({
      freq: RecurrenceHelper.toRRuleFrequency(this.frequency),
      interval: this.interval,
      byweekday: this.getByWeekdays(dateStart),
      bymonthday: this.getByMonthDays(dateStart),
      bymonth: this.getByMonth(dateStart),
      dtstart: moment.utc(dateStart).toDate(),
      until: this.endRepeat ? moment.utc(this.endRepeat).toDate() : null
    });
  }

  private getByMonth(dateStart: string | undefined): number[] | null {
    if (this.frequency !== 'YEARLY') {
      return null;
    }
    const months = this.frequencyRules;
    if (months.length === 0) {
      const def = moment.utc(dateStart).month() + 1;
      return [def];
    }
    return months;
  }

  private getByWeekdays(dateStart: string | undefined): number[] | null {
    if (this.frequency !== 'WEEKLY') {
      return null;
    }
    const wdays = this.frequencyRules;
    if (wdays.length === 0) {
      let def = moment.utc(dateStart).day();
      if (def === 0) {
        def = 7;
      }
      return [def];
    }
    // RRule week starts from 0, while our server counts week from 1
    return wdays.map((i) => i - 1);
  }

  private getByMonthDays(dateStart: string | undefined): number[] | null {
    if (this.frequency === 'MONTHLY' || this.frequency === 'YEARLY') {
      const mDays = this.frequencyRules;
      if (mDays.length === 0) {
        const def = moment.utc(dateStart).date();
        return [def];
      }
      return mDays;
    }
    return null;
  }
}
