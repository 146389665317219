<ng-container *ngIf="eventForm">
  <form [formGroup]="eventForm" class="add-update-form">
    <div class="closeElement" mat-dialog-close>
      <span class="material-icons">close</span>
    </div>

    <ng-container *ngIf="!showRepeatEventComponent">
      <div class="titleBlock">
        <h1 class="apg-title">{{isInEditMode ? 'Edit Event' : 'Add Event'}}</h1>
      </div>
      <mat-dialog-content>
        <!--    1-->

        <div class="apg-form-group">
          <label>Event type</label>

          <mat-select
            [panelClass]="'apg-mat-select'"
            formControlName="type"
            [class.errorInput]="eventForm.controls['type'].invalid && (eventForm.controls['type'].dirty || eventForm.controls['type'].touched)"
          >
            <mat-option
              *ngFor="let eventTypeOption of allEventTypes"
              [value]="eventTypeOption.option"
            >
              {{ eventTypeOption.option | titlecase}}
            </mat-option>
          </mat-select>

          <ng-container
            *ngIf="eventForm.controls['type'].invalid && eventForm.controls['type'].touched"
          >
            <div
              class="error"
              *ngIf="eventForm.get('type')?.hasError('required')"
            >
              Event type is required
            </div>
            <div
              class="error"
              *ngIf="eventForm.get('type')?.hasError('maxlength')"
            >
              Max length
              {{eventForm.get('type')?.errors?.maxlength?.requiredLength}}
              symbols
            </div>
          </ng-container>
        </div>

        <div class="apg-form-group" *ngIf="!workspace && !editMode">
          <label
            >Workspace
            <app-search-workspace-field
              [(selectedWorkspace)]="selectedWorkspace"
              #searchWorkspaceField
            ></app-search-workspace-field>
          </label>
        </div>

        <div class="apg-form-group">
          <label
            >Event title
            <input
              appTrim
              type="text"
              formControlName="name"
              maxlength="255"
              [class]="eventForm.controls['name'].invalid &&
                    (eventForm.controls['name'].dirty ||  eventForm.controls['name'].touched)
                    ? 'errorInput' : ''"
            />
          </label>

          <ng-container
            *ngIf="eventForm.controls['name'].invalid && eventForm.controls['name'].touched"
          >
            <div
              class="error"
              *ngIf="eventForm.get('name')?.hasError('required')"
            >
              Event title is required
            </div>
            <div
              class="error"
              *ngIf="eventForm.get('name')?.hasError('maxlength')"
            >
              Max length
              {{eventForm.get('name')?.errors?.maxlength?.requiredLength}}
              symbols
            </div>
          </ng-container>
        </div>

        <!--    2-->

        <div class="apg-row">
          <div class="apg-form-group apg-col-6">
            <label
              >Starts
              <input
                type="text"
                [ngxMatDatetimePicker]="picker1"
                placeholder="Choose a date"
                formControlName="dateStart"
                (keydown)="$event.preventDefault()"
                readonly="readonly"
                [class]="eventForm.controls['dateStart'].invalid &&
                        (eventForm.controls['dateStart'].dirty || eventForm.controls['dateStart'].touched)
                                ? 'errorInput' : ''"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="$any(picker1)"
                [disableRipple]="true"
              ></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker1 [enableMeridian]="true">
                <ngx-mat-datepicker-actions>
                  <button mat-button ngxMatDatepickerCancel>Cancel</button>
                  <button
                    mat-raised-button
                    color="accent"
                    ngxMatDatepickerApply
                  >
                    Apply
                  </button>
                </ngx-mat-datepicker-actions>
              </ngx-mat-datetime-picker>
            </label>

            <ng-container
              *ngIf="eventForm.controls['dateStart'].invalid &&
                (eventForm.controls['dateStart'].dirty || eventForm.controls['dateStart']?.touched)"
            >
              <div
                class="error"
                *ngIf="eventForm.controls['dateStart']?.errors?.required"
              >
                Should not be empty
              </div>
            </ng-container>
          </div>

          <div class="apg-form-group apg-col-6">
            <label
              >Ends
              <input
                type="text"
                [ngxMatDatetimePicker]="picker2"
                placeholder="Date Time"
                formControlName="dateEnd"
                readonly="readonly"
                (keydown)="$event.preventDefault()"
                [class]="eventForm.controls['dateEnd'].invalid &&
                        (eventForm.controls['dateEnd'].dirty ||  (eventForm.controls['dateEnd'].touched || eventForm.controls['dateStart'].touched ))
                                ? 'errorInput' : ''"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="$any(picker2)"
                [disableRipple]="true"
              ></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker2 [enableMeridian]="true">
                <ngx-mat-datepicker-actions>
                  <button mat-button ngxMatDatepickerCancel>Cancel</button>
                  <button
                    mat-raised-button
                    color="accent"
                    ngxMatDatepickerApply
                  >
                    Apply
                  </button>
                </ngx-mat-datepicker-actions>
              </ngx-mat-datetime-picker>
            </label>

            <ng-container
              *ngIf="eventForm.controls['dateEnd'].invalid &&
                (eventForm.controls['dateEnd'].dirty || (eventForm.controls['dateEnd'].touched || eventForm.controls['dateStart'].touched ))"
            >
              <div
                class="error"
                *ngIf="eventForm.controls['dateEnd']?.errors?.required"
              >
                Should not be empty
              </div>
              <div
                class="error"
                *ngIf="eventForm.controls['dateEnd']?.errors?.lessThenStart"
              >
                Cannot be less than start date
              </div>
            </ng-container>
          </div>
        </div>

        <div class="apg-form-group">
          <button
            [disabled]="repeatRuleDisabled"
            mat-button
            class="repeat-button"
            (click)="showRepeatComponent()"
          >
            <div
              fxLayout="row"
              [fxLayoutAlign]="recurrenceRule ? 'space-between center' : 'center center'"
            >
              <span>Repeat</span>
              <span *ngIf="recurrenceRule"
                >{{helper.toRecurrenceFrequency(recurrenceRule.options.freq) |
                titlecase}}</span
              >
            </div>
          </button>
        </div>

        <!--    3-->
        <div class="apg-form-group">
          <label
            >Location
            <input
              appTrim
              type="text"
              formControlName="location"
              [class]="eventForm.controls['location'].invalid &&
                    (eventForm.controls['location'].dirty ||  eventForm.controls['location'].touched)
                                ? 'errorInput' : ''"
            />
          </label>

          <ng-container
            *ngIf="eventForm.controls['location'].invalid &&
            (eventForm.controls['location'].dirty ||  eventForm.controls['location']?.touched)"
          >
            <div
              class="error"
              *ngIf="eventForm.controls['location']?.errors?.maxlength"
            >
              Max length 255 symbols
            </div>
          </ng-container>
        </div>

        <!--    4-->
        <div class="apg-form-group">
          <label
            >Link
            <input
              appTrim
              type="text"
              formControlName="link"
              [class]="eventForm.controls['link'].invalid &&
                    (eventForm.controls['link'].dirty ||  eventForm.controls['link'].touched)
                            ? 'errorInput' : ''"
            />
          </label>

          <ng-container
            *ngIf="eventForm.controls['link'].invalid &&
            (eventForm.controls['link'].dirty ||  eventForm.controls['link'].touched)"
          >
            <div
              class="error"
              *ngIf="eventForm.controls['link'].errors?.maxlength"
            >
              Max length 255 symbols
            </div>
          </ng-container>
        </div>

        <!--    5-->
        <div class="apg-form-group">
          <label for="add-update-form-control-description">Description</label>
          <div
            appTrim
            appClearFormatOnPaste
            appMentionable
            contenteditable="true"
            formControlName="description"
            id="add-update-form-control-description"
            [class.errorInput]="eventForm.controls['description'].invalid &&
                                (eventForm.controls['description'].dirty || eventForm.controls['description'].touched)"
            [fnSearchParticipant]="searchWorkspaceParticipants"
            [drawPosition]="isClientMobileSmallScreen ? MentionablePosition.BOTTOM : MentionablePosition.ABOVE"
          ></div>

          <ng-container
            *ngIf="eventForm.controls['description'].invalid &&
            (eventForm.controls['description'].dirty ||  eventForm.controls['description'].touched)"
          >
            <div
              class="error"
              *ngIf="eventForm.controls['description'].errors?.maxlength"
            >
              Max length 1500 symbols
            </div>
          </ng-container>
        </div>

        <div class="apg-form-group">
          <button
            mat-flat-button
            class="btn upper"
            color="accent"
            [class.spinner]="loadInProgress"
            [disabled]="(formSubmitAttempt && eventForm.invalid) || loadInProgress"
            (click)="save()"
          >
            <span>{{isInEditMode ? 'Edit' : 'Save'}}</span>
          </button>
        </div>
      </mat-dialog-content>
    </ng-container>

    <!-- Event repeat component -->

    <ng-container *ngIf="showRepeatEventComponent">
      <app-dialog-add-update-event-repeat
        [rule]="recurrenceRule"
        [startDate]="eventForm.value.dateStart"
        (save)="onRepeatEventsSave($event)"
        (cancel)="onRepeatEventsCancel()"
      ></app-dialog-add-update-event-repeat>
    </ng-container>
  </form>
</ng-container>
