import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AppConstants } from '../../models/appConstants';
import { EventBusNames, EventBusService } from '../event-bus/event-bus.service';

@Injectable({
  providedIn: 'root'
})
export class AppConstantsService {
  appConstants: AppConstants = {};

  private appConstantsPath = environment.url + 'app/constants';

  constructor(
    private http: HttpClient,
    eventBusService: EventBusService
  ) {
    eventBusService.on(EventBusNames.authorized, this.getConstants.bind(this));
  }

  private getConstants(): void {
    this.http
      .get<AppConstants>(this.appConstantsPath)
      .subscribe((constants) => (this.appConstants = constants));
  }
}
