import { AvatarModule } from './../../component/avatar/avatar.module';
import { ShareModule } from './../../component/share/share.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DialogInfoComponent } from '../../dialog/dialog-info/dialog-info.component';
import { CustomSnackbarComponent } from '../../pages/custom-snackbar/custom-snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { RightColumnComponent } from '../landing-layout/pages/first-page-layout/right-column/right-column.component';
import { SortByPipe } from '../../common/pipe/sort-by.pipe';
import { LoadingComponent } from '../../component/loading/loading.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SelectableButtonDirective } from '../../directive/selectable-button.directive';
import { MentionableDirectiveModule } from '../../directive/mentionable.directive';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { SearchWorkspaceFieldComponent } from '../../pages/workspace/search-workspace-field/search-workspace-field.component';
import { PipesModule } from '../pipes/pipes.module';
import { GroupSelectComponent } from '../../standalone/group-select/group-select.component';
import { FloatButtonComponent } from 'src/app/standalone/float-button/float-button.component';
import { EmptyContentComponent } from 'src/app/component/empty-content/empty-content.component';
import { LogoHeaderComponent } from 'src/app/component/logo-header/logo-header.component';

@NgModule({
  declarations: [
    DialogInfoComponent,
    CustomSnackbarComponent,
    RightColumnComponent,
    SortByPipe,
    SelectableButtonDirective,
    SearchWorkspaceFieldComponent
  ],
  imports: [
    LogoHeaderComponent,
    NgxSpinnerModule,
    CommonModule,
    FlexLayoutModule,
    EmptyContentComponent,
    RouterModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatMenuModule,
    FormsModule,
    PipesModule,
    LoadingComponent,
    ShareModule,
    AvatarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MatIconModule,
    MatDialogModule,
    GroupSelectComponent,
    FloatButtonComponent
  ],
  exports: [
    CommonModule,
    AvatarModule,
    ShareModule,
    FlexLayoutModule,
    EmptyContentComponent,
    FloatButtonComponent,
    MatAutocompleteModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatMenuModule,
    FormsModule,
    TranslateModule,
    PipesModule,
    CustomSnackbarComponent,
    RightColumnComponent,
    SortByPipe,
    LoadingComponent,
    SelectableButtonDirective,
    MentionableDirectiveModule,
    SearchWorkspaceFieldComponent,
    LogoHeaderComponent
  ]
})
export class SharedModule {}

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
